// TODO - Documentar
import React from 'react'
import {StaticTable} from '../../templates/tables/StaticTable'

import * as con from "../../GlobalConstants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { RefTransactionsSummaryTableHook } from '../hooks/TransactionsSummaryTableHook'
import { nonZeroTranactionFunction, prepareForExport } from '../../utils/transactionFunctions'
import { TransactionColumnsHook } from '../hooks/TransactionsHooks'
import { Button } from '@blueprintjs/core'
import { downloadExcelFromArray } from '../../utils/downloadFunctions'
import { constructFileName } from '../../utils/reportFunctions'



export const BandPositionSummary = ({selectedColumns}) => {


  // Parameters
  // -----------------  
  const onlyNonZeroRows = RefApplicationParameterHook(con.ONLY_NON_ZERO_ROWS)
  
  // Transactions Summary Hook
  const [transactionSummaryObject, summaryRow, computing, hasSummaryTable] = RefTransactionsSummaryTableHook()

  const transactionsCol = TransactionColumnsHook(selectedColumns, true)
                          

    return ( 
            <div > 
              { 

               computing ? <h5>Computando...</h5> 
                         : !hasSummaryTable ? <h4>{con.NO_TRANSACTIONS_MESSAGE}</h4> 
                                                                : <div>
                                                                    <StaticTable columnArray={transactionsCol} 
                                                                              dataDictionary={transactionSummaryObject}
                                                                              summaryRow={summaryRow}
                                                                              filterFunction={ob =>  !onlyNonZeroRows || nonZeroTranactionFunction(ob)} />
                                                                    <div style={{marginTop : "10px"}}>
                                                                      <Button icon="download" text="Descargar" onClick={() => downloadExcelFromArray(prepareForExport(transactionSummaryObject, transactionsCol), constructFileName(con.TRANSACTIONS_SUMMARY_SHEET_NAME, con.XLSX_FILE_TYPE))}/>   
                                                                    </div> 
                                                                  </div> 
              }
            </div> 

       
    )
}

BandPositionSummary.defaultProps = {
  selectedColumns: [{ [con.ID] : con.DATE},
                    { [con.ID] : con.EXPOSURE_AMOUNT},
                    { [con.ID] : con.COVERAGE_AMOUNT},                      
                    { [con.ID] : con.RATE},
                    { [con.ID] : con.COVERAGE_PERCENTAGE},   
                    { [con.ID] : con.POSITION_IN_BANDS},    

                    { [con.ID] : `${con.LOWER_BAND}_var_x_usd`},
                    { [con.ID] : `${con.MID_LOW_BAND}_var_x_usd`},
                    { [con.ID] : `${con.MID_BAND}_var_x_usd`},
                    { [con.ID] : `${con.MID_HIGH_BAND}_var_x_usd`},
                    { [con.ID] : `${con.HIGHER_BAND}_var_x_usd`}, 

                    { [con.ID] : `${con.LOWER_BAND}_var`},
                    { [con.ID] : `${con.MID_LOW_BAND}_var`},
                    { [con.ID] : `${con.MID_BAND}_var`},
                    { [con.ID] : `${con.MID_HIGH_BAND}_var`},
                    { [con.ID] : `${con.HIGHER_BAND}_var`}]    
};



