// Functions for the Forward Calculator

import * as con from "../GlobalConstants"
import { sum, weightedAverage } from "../GlobalFunctions"
import { daysBetweenDates, parseDate } from "./dateFunctions"
import { getClosestTRM } from "./marketFunctions"



/**
 * Method that return the generating function of the devaluation, extrapolating from a given an array of days as reference and their corresponding devaluation value. 
 * The intermitente days are approximately linearly. Days after the max reference day are computed using the average slope of the reference values.
 * @param {Array<number>} days - Array with numbers representing the days of the objective scenario 
 * @param {Array<number>} values - Array with numbers representing the value of the currency at the corresponding day
 * @returns {Function} Function that receives the target day, to return the corresponding devaluation percentage
 */
 export const getGeneratorDevaluationFunctionByDay = (days, values) =>
 {
 
     // Extracts max day
     const max_day =  days[days.length -1]
     let min_day = days[0]
 
     if(days.length !== values.length)
         throw new Error(`Day array (length ${days.length}) must be the same length as the values array (length ${values.length})`)
 
    if(min_day !== 0)
    {
        days.unshift(0)
        values.unshift(values)
        min_day = 0
    }
             
          
     // Extracts the average of growth to use it when projecting the scenario indefinetly
     // Jump in days
     const jumps = days.map((_, i) => days[Math.min(i + 1, days.length - 1)] - days[i])
     jumps.pop()
     
     // slopes
     const slopes = jumps.map((_, i) => (values[i+1] - values[i])/jumps[i])
 
     // Extracts average growth
     // Weighted average
     let avg = jumps.map((_, i) => jumps[i]*slopes[i]).reduce((a,b) => a+b)/jumps.reduce((a,b) => a+b) 
 
 
     // Creates generating function
     let fun = (d) => {
 
         // Max day exceeded. 
         // Uses average
         if(d >= max_day)
             return(avg*(d-max_day) + values[values.length-1])
         
        if(d < 0)
            return(values[0])
         // Finds the construction day immediately before the given one
         let x = days.filter((di) => di <= d).pop()
         let i = days.indexOf(x)
         return( slopes[i]*(d-x) + values[i])
     }
 
 
     return(fun)    
 
 }
 



/**
 * Method that returns the forward curve value given the devaluation and spot
 * @param {number} dev - Float value with devaluation
 * @param {number} spot - Spot value
 * @param {number} days - Corresponding days
 * @returns {function} Function that receives the current SPOT and the target day, to return the scenario's value on that day.
 */
 export const computeForwardValueFromDevaluationAndSpot = (dev, spot, days) =>
 {
    let fwd = spot*((1+dev)**(days/con.DAYS_IN_YEAR_FORWARDS))
    return(fwd)
 }

 /**
 * Method that returns the devaluation given the forward value and spot
 * @param {number} fwd - Float value with forward rate
 * @param {number} spot - Spot value
 * @param {number} days - Corresponding days
 * @returns {function} Function that receives the current SPOT and the target day, to return the scenario's value on that day.
 */
  export const computeDevaluationFromForwardValueAndSpot = (fwd, spot, days ) =>
  {
 
    let dev = (fwd/spot)**(con.DAYS_IN_YEAR_FORWARDS/days) - 1
    
    return(dev)
   
     
  }
 


/** LEGACY
 * Method that determines if a forward coverage is active given the assessment date
 * @param {date} startFwdAssessmentDate - Assessment date
 * @param {date} assessmentDate - Assessment date
 * @param {number} fwd - Forward coverage object
 * @param {bool} [acceptExpiredCoverages=false] - Boolean indicating if expired coverages should be included
 * @returns {bool} Boolean indicating if the forward coverage is active or not
 */
 export const isForwardCoverageActiveForAssessment = (assessmentDate, fwd, acceptExpiredCoverages = false, startFwdAssessmentDate) =>
 {
    let resp = parseDate(fwd[con.OPENING_DATE]) <= assessmentDate;
    if(acceptExpiredCoverages){
        resp = resp && (startFwdAssessmentDate <= parseDate(fwd[con.EXPIRATION_DATE]))
    } else {
        resp = resp && (parseDate(fwd[con.EXPIRATION_DATE]) >= assessmentDate)
    }
    
    return(resp)
 }

/**
 * Method that computes the Forward Assessment Columns
 * @param {object} fwd - Forward Coverage Object
 * @param {date} referenceDate - Reference date for assesment
 * @param {number} rate - Current rate to be used. Should correspond to the SPOT or to the TRM
 * @param {function} devaluationFunction - Function that returns the devaluation given the day
 * @param {function} ibrFunction - Function that returns the Incremental Borrowing Rate given the day
 * @returns {object} New object including the Assessment columns
 */
 export const computeForwardAssessmentColumns = (fwd, referenceDate, rate, devaluationFunction, ibrFunction) =>
 {

    // Computes Expiry Days
    fwd[con.EXPIRY_DAYS] = daysBetweenDates(referenceDate, fwd[con.EXPIRATION_DATE], false)
    
    if(fwd[con.EXPIRY_DAYS] < 0)
    {
        
        fwd[con.EXPIRY_DAYS] = 0
        fwd[con.DEVALUATION_VALUE] = 0
        fwd[con.DISCOUNT_RATE] = 0

        // Forward Rate
        fwd[con.NEW_FORWARD_RATE] = getClosestTRM(fwd[con.EXPIRATION_DATE])

        // Difference
        fwd[con.FORWARD_RATE_DIFFERENCE] = fwd[con.NEW_FORWARD_RATE] - fwd[con.RATE]
        fwd[con.FORWARD_RATE_DIFFERENCE_PRESENT_VALUE] = fwd[con.FORWARD_RATE_DIFFERENCE] 

    }
    else
    {
        // Devaluation Value
        fwd[con.DEVALUATION_VALUE] = devaluationFunction(fwd[con.EXPIRY_DAYS])
        
        // Discount -15bps to devaluation value if it's a sell fwd
        fwd[con.DEVALUATION_VALUE] = fwd[con.COVERAGE_TYPE] === con.SELL ? fwd[con.DEVALUATION_VALUE] - con.DEVALUATION_FWD_SPREAD_BUY_SELL : fwd[con.DEVALUATION_VALUE]

        // Forward Rate
        fwd[con.NEW_FORWARD_RATE] = rate*((1 + fwd[con.DEVALUATION_VALUE])**((fwd[con.EXPIRY_DAYS]/con.DAYS_IN_YEAR_FORWARDS)))

        //Discount rate
        fwd[con.DISCOUNT_RATE] = ibrFunction(fwd[con.EXPIRY_DAYS])

        // Difference
        fwd[con.FORWARD_RATE_DIFFERENCE] = fwd[con.NEW_FORWARD_RATE] - fwd[con.RATE]        
        fwd[con.FORWARD_RATE_DIFFERENCE_PRESENT_VALUE] = fwd[con.FORWARD_RATE_DIFFERENCE]/((1 + fwd[con.DISCOUNT_RATE])**((fwd[con.EXPIRY_DAYS]/con.DAYS_IN_YEAR_FORWARDS)))
    }

    // Assessment
    fwd[con.ASSESSMENT] = fwd[con.FORWARD_RATE_DIFFERENCE_PRESENT_VALUE]*fwd[con.AMOUNT]
    fwd[con.ASSESSMENT] = fwd[con.COVERAGE_TYPE] === con.BUY ? fwd[con.ASSESSMENT] : -1*fwd[con.ASSESSMENT] 

    return(fwd)
 }



/**
 * Method that computes the Forward Assessment Summary
 * @param {array} assessedFwdArray - All assessed Forward Coverages array
 * @param {function} fwd_assessment_filter_fun - Forward assessment filter function to determinate active forward coverage
 * @returns {object} An object with the summary values
 */
 export const computeForwardAssessmentSummary = (assessedFwdArray, fwd_assessment_filter_fun, currentForwardRate) =>
 {

    let result = {[con.ACTIVE_FWD_AMOUNT] : 0,
                        [con.AVERAGE_FWD_RATE] : 0,
                        [con.ASSESSMENT_RATE] : 0,
                        [con.PORTFOLIO_ASSESSMENT] : 0}

    // Filters by expiry days
    assessedFwdArray = assessedFwdArray.filter( fwd => fwd_assessment_filter_fun(fwd))
    if(assessedFwdArray.length === 0)
        return(result)
    
    // Computes Amount
    result[con.ACTIVE_FWD_AMOUNT] = sum(assessedFwdArray.map(fwd => fwd[con.AMOUNT]))

    // Assessment Rate
    result[con.ASSESSMENT_RATE] = currentForwardRate

    // Average Forward Rate
    result[con.AVERAGE_FWD_RATE] = weightedAverage(assessedFwdArray.map(fwd => fwd[con.RATE]), assessedFwdArray.map(fwd => fwd[con.AMOUNT]))
    
    // Portafolio Assessment
    result[con.PORTFOLIO_ASSESSMENT] = sum(assessedFwdArray.map(fwd => fwd[con.ASSESSMENT]))

    return(result)
}
