import React from 'react';
import { CellText } from '../../templates/tables/Cells';

function SecondLeftRow({ data }) {
    return (
        <div className="flex-fila" style={{ padding: '10px 20px', textAlign: 'center', whiteSpace: 'pre-line' }}>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={data.cierreAnterior} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={data.diffCierreAnterior} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={data.trmReal} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={data.volatilidadJornada} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={`${data.variacionSemanal} (${data.variacionSemanalPorcentaje.toFixed(2)}%)`} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={data.indicadorAgotamiento} editing={false} />
            </div>
        </div>
    );
}

export default SecondLeftRow;
