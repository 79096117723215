import React from 'react'
import * as con from "../../GlobalConstants"
import CustomRealTimeWidgetInitialView from '../tradingViewComponentes/CustomRealTimeWidgetInitialView'
import GlobalViewComponent from './GlobalViewComponent'


function MarketViewGlobalViewComponent() {
  return (

        <GlobalViewComponent pageLink={con.PAGE_MARKETS_TECHNICAL_ANALYSIS}
                            title={"MERCADO"}>                        
                <CustomRealTimeWidgetInitialView/>                    
        </GlobalViewComponent>                                

  )
}

export default MarketViewGlobalViewComponent