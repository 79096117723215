import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { DateTime } from 'luxon';
import { Button } from '@blueprintjs/core';

const CandleStickChart = ({ data }) => {
    const [series, setSeries] = useState([]);
    const [interval, setInterval] = useState(15); // Intervalo inicial de 15 minutos

    useEffect(() => {
        const formatDataForCandleStick = (data, interval) => {
            const intervals = [];
            let currentInterval = null;

            for (let i = 0; i < data.dates.length; i++) {
                const date = DateTime.fromISO(data.dates[i]);
                const intervalStart = Math.floor(date.toMillis() / (interval * 60 * 1000)) * (interval * 60 * 1000);

                if (!currentInterval || currentInterval.intervalStart !== intervalStart) {
                    if (currentInterval) {
                        const high = Math.max(...currentInterval.closes);
                        const low = Math.min(...currentInterval.closes);
                        const open = currentInterval.closes[0]; 
                        const close = currentInterval.closes[currentInterval.closes.length - 1]; 

                        intervals.push({
                            x: new Date(currentInterval.intervalStart),
                            y: [open, high, low, close],
                        });
                    }

                    currentInterval = {
                        intervalStart,
                        closes: [data.close[i]], 
                    };
                } else {
                    currentInterval.closes.push(data.close[i]);
                }
            }

            if (currentInterval) {
                const high = Math.max(...currentInterval.closes);
                const low = Math.min(...currentInterval.closes);
                const open = currentInterval.closes[0];
                const close = currentInterval.closes[currentInterval.closes.length - 1];

                intervals.push({
                    x: new Date(currentInterval.intervalStart),
                    y: [open, high, low, close],
                });
            }

            return intervals;
        };

        const formattedData = formatDataForCandleStick(data, interval);
        setSeries([{ data: formattedData }]);
    }, [data, interval]);

    const options = {
        chart: {
            type: 'candlestick',
            height: 350,
            background: 'transparent',  
        },
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            tooltip: {
                enabled: true,
            },
        },
        theme: {
            mode: 'dark',
        },
        tooltip: {
            theme: 'dark',
        },
        fill: {
            opacity: 1,
        },
        states: {
            hover: {
                filter: {
                    type: 'lighten',
                    value: 0.05,
                },
            },
        },
        dropShadow: {
            enabled: true,
            top: 3,
            left: 3,
            blur: 3,
            opacity: 0.5,
        },
        grid: {
            borderColor: '#555',
        },
        dataLabels: {
            enabled: false,
        },
    };

    return (
        <div id="chart" style={{ marginTop: '20px', textAlign: 'center' , padding: '0 20px' }}>
            <ReactApexChart options={options} series={series} type="candlestick" height={350} />
            <div style={{ marginTop: '0px', textAlign: 'center' }}>
                <Button
                    text="15 Minutos"
                    onClick={() => setInterval(15)}
                    style={{
                        backgroundColor: 'rgb(238, 172, 24)', 
                        color: 'black',
                        fontSize: '18px', 
                        lineHeight: '24px', 
                        fontWeight: 'bold', 
                        padding: '10px 20px', 
                        margin: '5px'
                    }}
                />
                <Button
                    text="1 Hora"
                    onClick={() => setInterval(60)}
                    style={{
                        backgroundColor: 'rgb(238, 172, 24)', 
                        color: 'black',
                        fontSize: '18px', 
                        lineHeight: '24px', 
                        fontWeight: 'bold', 
                        padding: '10px 20px', 
                        margin: '5px'
                    }}
                />
                <Button
                    text="1 Día"
                    onClick={() => setInterval(1440)}
                    style={{
                        backgroundColor: 'rgb(238, 172, 24)', 
                        color: 'black',
                        fontSize: '18px', 
                        lineHeight: '24px', 
                        fontWeight: 'bold', 
                        padding: '10px 20px', 
                        margin: '5px'
                    }}
                />
            </div>
        </div>
    );
};

export default CandleStickChart;
