// Global view page
// TODO - Documentar
import React from 'react'
import "./MobilePages.css"


import * as con from "../GlobalConstants"


import MarketViewGlobalViewComponent from '../components/globalViewComponents/MarketViewGlobalViewComponent';
import CurrentPositionGlobalViewComponent from '../components/globalViewComponents/CurrentPositionGlobalViewComponent';


function MobileGlobalView() {


    const componentHeight = con.MOBILE_USABLE_SCREEN_SIZE

    

    return (      
            <div style={{minHeight : `${componentHeight}vh`, paddingTop: "15px"}}>
               <CurrentPositionGlobalViewComponent componentHeight={componentHeight/2}/>      
               <MarketViewGlobalViewComponent/>

            </div>
    )
}




export default MobileGlobalView
