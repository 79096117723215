import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HistoricalDataTable from '../components/tables/HistoricalDataTable';
import MarketSummaryTable from '../components/tables/MarketSummaryTable';
import { USABLE_SCREEN_SIZE } from '../GlobalConstants';
import { Button } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { GenericCell } from '../templates/tables/Cells';
import * as XLSX from 'xlsx';

function HistoricalDataPage() {
    const historicalDollarInfo = useSelector((state) => state['store']['market_values']['historic_dollar_info']);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (historicalDollarInfo && historicalDollarInfo.dates.length > 0) {
            const lastDate = DateTime.fromISO(historicalDollarInfo.dates[historicalDollarInfo.dates.length - 1]).toJSDate();
            const oneMonthBeforeLastDate = DateTime.fromJSDate(lastDate).minus({ months: 1 }).toJSDate();

            setStartDate(oneMonthBeforeLastDate);
            setEndDate(lastDate);
        }
    }, [historicalDollarInfo]);

    useEffect(() => {
        if (historicalDollarInfo) {
            const data = historicalDollarInfo.dates.map((date, index) => ({
                fecha: DateTime.fromISO(date),
                monto: historicalDollarInfo.volume[index],
                trm: historicalDollarInfo.trm[index],
                apertura: historicalDollarInfo.open[index],
                max: historicalDollarInfo.high[index],
                min: historicalDollarInfo.low[index],
                cierre: historicalDollarInfo.close[index],
            }));

            const filtered = data.filter(item => 
                (!startDate || item.fecha >= DateTime.fromJSDate(startDate)) &&
                (!endDate || item.fecha <= DateTime.fromJSDate(endDate))
            );

            setFilteredData(filtered.reverse());
        }
    }, [startDate, endDate, historicalDollarInfo]);

    const handleDownload = () => {
        const ws = XLSX.utils.json_to_sheet(filteredData.map(item => ({
            Fecha: item.fecha.toISODate(),
            Monto: item.monto,
            TRM: item.trm,
            Apertura: item.apertura,
            Máx: item.max,
            Mín: item.min,
            Cierre: item.cierre,
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Datos Filtrados");
        XLSX.writeFile(wb, "datos_filtrados.xlsx");
    };

    return (
        <div className="gridBox" style={{ height: `${USABLE_SCREEN_SIZE}vh`, padding: '20px', display: 'flex', width: '100%' }}>
            {/* Columna Izquierda  */}
            <div style={{ flex: 5, marginRight: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', gap: '15px' }}>
                    <h2 style={{ margin: 0 }}>INFORMACIÓN HISTÓRICA DEL DÓLAR</h2>
                    <Button 
                        icon="download" 
                        text="Descargar Excel" 
                        onClick={handleDownload} 
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginTop: '20px' }}>
                    <div>
                        <label style={{ fontSize: '18px', marginRight: '10px' }}>Fecha Inicio:</label>
                        <GenericCell
                            cellType="date"
                            value={startDate ? DateTime.fromJSDate(startDate).toISODate() : ''}
                            setValue={(val) => setStartDate(DateTime.fromISO(val).toJSDate())}
                            editing={true}
                            style={{ fontSize: '20px', padding: '10px', height: 'auto', width: 'auto' }}
                        />
                    </div>
                    <div>
                        <label style={{ fontSize: '18px', marginRight: '10px' }}>Fecha Fin:</label>
                        <GenericCell
                            cellType="date"
                            value={endDate ? DateTime.fromJSDate(endDate).toISODate() : ''}
                            setValue={(val) => setEndDate(DateTime.fromISO(val).toJSDate())}
                            editing={true}
                            style={{ fontSize: '20px', padding: '10px', height: 'auto', width: 'auto' }}
                        />
                    </div>
                </div>

                <div style={{ marginTop: '40px' }}>
                    <HistoricalDataTable fixedSize="45" data={filteredData} />
                </div>
            </div>

            {/* Columna Derecha  */}
            <div style={{ flex: 5, marginTop: '-300px',marginLeft: '40px'  }}>
                <div className="columnParameterBox">
                    <h2 style={{ textAlign: 'center' }}>RESUMEN MERCADO</h2>
                    <MarketSummaryTable fixedSize="45" filteredData={filteredData} />
                </div>
            </div>
        </div>
    );
}

export default HistoricalDataPage;
