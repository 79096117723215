import React from 'react';
import { DateTime } from 'luxon';
import { CellText } from '../../templates/tables/Cells';

function IntradayRecordsRow({ rowId, data }) {
    const date = DateTime.fromISO(data.fecha).setLocale('es');
    let formattedDate = date.toFormat('d MMM yyyy, hh:mm:ss a');
    formattedDate = formattedDate.replace(
        /(\d+)\s(\w+)\s(\d{4})/,
        (_, day, month, year) => `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`
    );

    return (
        <div className={`flex-fila hoverable-row ${(rowId % 2 === 0) ? "even-row" : "uneven-row"}`} style={{ padding: '5px 0' }}>
            <div className="flex-celda" style={{ textAlign: 'center', padding: '5px', minWidth: '150px' }}>
                <CellText value={formattedDate} editing={false} />
            </div>
            <div className="flex-celda" style={{ textAlign: 'center', padding: '5px' }}>
                <CellText value={data.max} editing={false} />
            </div>
            <div className="flex-celda" style={{ textAlign: 'center', padding: '5px' }}>
                <CellText value={data.min} editing={false} />
            </div>
            <div className="flex-celda" style={{ textAlign: 'center', padding: '5px' }}>
                <CellText value={data.cierre} editing={false} />
            </div>
        </div>
    );
}

export default IntradayRecordsRow;
