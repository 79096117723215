import React, { useState, useEffect } from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import { fontCalculator } from '../../templates/tables/StaticTable';
import IntradayRecordsRow from './IntradayRecordsRow';

import chevron from '../../img/icons/chevron.png';
import doubleChevron from '../../img/icons/double-chevron.png';

function IntradayRecordsTable({ fixedSize, data }) {
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 16;

    useEffect(() => {
        setCurrentPage(1);
    }, [data]);

    if (!data || data.dates.length === 0) {
        return <div>No hay datos disponibles</div>;
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.dates.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(data.dates.length / recordsPerPage);

    const handleFirstPage = () => setCurrentPage(1);
    const handlePreviousPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    const handleNextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    const handleLastPage = () => setCurrentPage(totalPages);

    return (
        <div>
            <div 
                className="pagination" 
                style={{ 
                    textAlign: 'center', 
                    marginBottom: '14px',  
                    fontSize: '14px',  
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <button 
                    onClick={handleFirstPage} 
                    disabled={currentPage === 1} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={doubleChevron} alt="Primera página" style={{ width: '16px', height: '16px', transform: 'scaleX(-1)', filter: 'invert(100%)' }} />
                </button>
                <button 
                    onClick={handlePreviousPage} 
                    disabled={currentPage === 1} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={chevron} alt="Página anterior" style={{ width: '16px', height: '16px', transform: 'scaleX(-1)', filter: 'invert(100%)' }} />
                </button>
                <span style={{ margin: '0 10px', color: 'white', lineHeight: '16px', marginTop: '-4px' }}>
                    Página {currentPage} de {totalPages}
                </span>
                <button 
                    onClick={handleNextPage} 
                    disabled={currentPage === totalPages} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={chevron} alt="Siguiente página" style={{ width: '16px', height: '16px', filter: 'invert(100%)' }} />
                </button>
                <button 
                    onClick={handleLastPage} 
                    disabled={currentPage === totalPages} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={doubleChevron} alt="Última página" style={{ width: '16px', height: '16px', filter: 'invert(100%)' }} />
                </button>
            </div>

            <div className="contenedor-tabla" style={fixedSize == null ? {} : { fontSize: fontCalculator(fixedSize, recordsPerPage + 2) }}>
                <div className="flex-tabla">
                    <div className="flex-fila header-row">
                        <div className="flex-celda" style={{ padding: '10px 10px', textAlign: 'center' }}>Fecha</div>
                        <div className="flex-celda" style={{ padding: '5px 10px', textAlign: 'center' }}>Max</div>
                        <div className="flex-celda" style={{ padding: '5px 10px', textAlign: 'center' }}>Min</div>
                        <div className="flex-celda" style={{ padding: '5px 10px', textAlign: 'center' }}>Cierre</div>
                    </div>
                </div>

                <div className="flex-tabla">
                    {currentRecords.map((_, index) => (
                        <IntradayRecordsRow 
                            key={index} 
                            rowId={index}
                            data={{
                                fecha: data.dates[indexOfFirstRecord + index],
                                max: data.high[indexOfFirstRecord + index],
                                min: data.low[indexOfFirstRecord + index],
                                cierre: data.close[indexOfFirstRecord + index]
                            }}
                        />
                    ))}
                </div>
            </div>

        </div>
    );
}

IntradayRecordsTable.defaultProps = {
    fixedSize: null,
};

export default IntradayRecordsTable;
