import { formatDate, getToday } from "./utils/dateFunctions";
import * as con from "./GlobalConstants"
import { translateBandsShort } from "./utils/translateFunctions";
import initialParameters from "./config/initial_parameters.json"

// Custom round method
export const round = (val, spaces = 0) =>
{
    const d = 10**spaces
    return(Math.round(d*val)/d)
}

export const isInt = (value) => 
{
    var x;
    if (isNaN(value)) {
      return false;
    }
    x = parseFloat(value);
    return (x | 0) === x;
  }

export const isNumeric = (value) => 
{
    if (isNaN(value)) {
      return false;
    }
    return ! isNaN(parseFloat(value));
}

// Functions that gets the default value for a given transaction column
export const getDefaultValue = (columnType) =>
{

    switch(columnType)
    {
        case con.EXPIRATION_DATE:
        case con.OPENING_DATE:            
            return(formatDate(getToday()))
        case con.COMMENT:
            return("")
        case con.STATE:
            return(con.ACTIVE)
        case con.RATE:
        case con.AMOUNT:    
        case con.OPENING_SPOT:
            return(0)
        case con.COVERAGE_TYPE:
            return(con.BUY)
        case con.SPOT_COVERAGE_TYPE:
            return(con.CASH_REGISTER)
        case con.ACCOUNT_TYPE:
            return(con.COMPENSATION_ACCOUNT)
        default:
            throw new Error(`No support for column type: ${columnType}`)   
    }

}

// Functions that takes the average of the given array
export const average = (arr) => arr.reduce((a,b) => a + b, 0) / arr.length;


// Functions that sums array
export const sum = (arr) => arr.reduce((a,b) => a + b, 0)

// Functions that get the max of an array
export const max = (arr) => arr.reduce((a,b) => Math.max(a,b))

// Functions that get the min of an array
export const min = (arr) => arr.reduce((a,b) => Math.min(a,b))

// Functions that takes the weighted average of the given array
export const weightedAverage = (arr, weight) => sum(arr.map((a,i) => a*weight[i])) / sum(weight)

// Function for filtering Objects
export const filterObject = (obj, predicate) => {

    return(Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                .reduce( (res, key) => { return Object.assign(res, { [key]: obj[key] })}, {}))

                
}

// Function for filtering Objects
export const filterObjectByKeys = (obj, keys) => {

    return(Object.keys(obj)
                .filter( key => keys.includes(key) )
                .reduce( (res, key) => { return Object.assign(res, { [key]: obj[key] })}, {}))

                
}

// Clean object
export const cleanObject = (ob) => {
    return(filterObject(ob, (o) => o !== null && o !== undefined))
}

// Function for deleting attribute fro element
export const deleteAttributeFromObject = (obj, k) =>
{
    return(Object.keys(obj)
                .filter( key => key !== k )
                .reduce( (res, key) => { return Object.assign(res, { [key]: obj[key] })}, {}))
}

export const size = (ob) =>
{
    return(Object.keys(ob).length)
}

export const arrayToObjectByAttribute = (arr, attr = con.ID) =>
{
    return(Object.fromEntries(arr.map(ob => [ob[attr], ob])))
}

export const sortArrayByAttribute = (arr, k) =>
{
    return(arr.sort((a, b) => {
        let fa = a[k].toLowerCase(),
            fb = b[k].toLowerCase();
    
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    }))
}

export const buildSurrogateUserTransactionsDict = (surrogateUserDict, depth = 1) =>
{
    const finalDict = {}
    Object.keys(surrogateUserDict).forEach(id => {
        finalDict[id] = {}
        surrogateUserDict[id][con.LINES].forEach(line =>{
            if(depth === 1)
            {
                finalDict[id][line[con.ID]] = {
                    [con.MODEL_PARAMETERS] : {...initialParameters[con.DEFAULT_MODEL_PARAMETERS]},
                    [con.EXPOSURES] : {},
                    [con.COVERAGES_FWD] : {},
                    [con.COVERAGES_SPOT] : {},
                    [con.ACCOUNTS] : {},
                }
            }
            else
            {
                finalDict[id][line[con.ID]] = {}
            }

        })

    })

    return(finalDict)
}


export const buildSurrogateUserTransactionsDictWithValue = (surrogateUserDict, value) =>
{
    const finalDict = {}
    Object.keys(surrogateUserDict).forEach(id => {
        finalDict[id] = {}
        surrogateUserDict[id][con.LINES].forEach(line =>{            
                finalDict[id][line[con.ID]] = value            

        })

    })

    return(finalDict)
}

export const buildSurrogateUserTransactionsDictWithObject = (surrogateUserDict, ob) =>
{
    const finalDict = {}
    Object.keys(surrogateUserDict).forEach(id => {
        finalDict[id] = {}
        surrogateUserDict[id][con.LINES].forEach(line =>{            
                finalDict[id][line[con.ID]] = {...ob}            

        })

    })

    return(finalDict)
}

export const allDashboardEnabledUsers = (usr) => usr[con.PROFILE] === null || usr[con.PROFILE][con.INCLUDE_IN_ADVISOR_PANEL] 

    
export const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };



export const buildTransactionsSummaryDownloadFileName = (selectedBand ) => `${con.TRANSACTIONS_SUMMARY_SHEET_NAME} ${translateBandsShort(selectedBand)}`


export const daysIdToInt = (daysID) => {

    return(parseInt(daysID.replace("market_","").replace("_days")))

}


export const binaryIndexSearch = (elem, array, exact = false, compareFun = (a,b) => a-b, distFun = (a,b) => Math.abs(a-b)) =>
{
    let mid;
    let low = 0
    let high = array.length

    while (high - low > 1) {
        mid = Math.floor ((low + high) / 2);
        if(compareFun(array[mid],elem) === 0) 
            return(mid)
        else if(compareFun(array[mid],elem) < 0)        
            low = mid
        else
            high = mid;        
    }

    // Checks edges
    if(compareFun(array[low],elem) === 0)
        return(low)
    
    if(compareFun(array[high],elem) === 0)
        return(high)

    // Exacts returns null
    if(exact)
        return(null)

    // Finds Closest
    if(distFun(array[high], elem) < distFun(array[low], elem))
        return(high)

    return(low)
}


export const isSorted = (arr) =>
{
    let minDif = min(arr.map((ob,i) => i === arr.length - 1 ? 0 : arr[i+1] - arr[i]))

    return(minDif >= 0)
}

export const standardNormalDistribution = (value) => {
    const erf = (z) => {
        const t = 1.0 / (1.0 + 0.5 * Math.abs(z));
        const ans = 1 - t * Math.exp( -z*z -  1.26551223 +
                                 t * ( 1.00002368 +
                                 t * ( 0.37409196 + 
                                 t * ( 0.09678418 + 
                                 t * (-0.18628806 + 
                                 t * ( 0.27886807 + 
                                 t * (-1.13520398 + 
                                 t * ( 1.48851587 + 
                                 t * (-0.82215223 + 
                                 t * ( 0.17087277))))))))));
        return z >= 0 ? ans : -ans;
    };
    return 0.5 * (1 + erf(value / Math.sqrt(2)));
}
