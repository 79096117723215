// TODO - Documentar
// Transaction Summary Hook
import {useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import * as con from "../../GlobalConstants"
import { processTransactionsWithSummary } from '../../utils/transactionFunctions'
import { RefApplicationParameterHook, RefModelApplicationParameterHook, RefSPOT } from './ApplicationParametersHooks'
import { AllModelParameterHook } from './ModelParameterHooks'
import { AccountsHook, ExposuresHook, ForwardCoveragesHook, OptionCoveragesHook } from './TransactionsHooks'
import { RefMarketValueHook } from './MarketValuesHook'
import { fetchVolatilitySurfaceByDate } from '../../store/actions/market'
import { formatDate } from '../../utils/dateFunctions'
import { getGeneratorDevaluationFunctionByDay } from '../../utils/forwardFunctions'
import { arrayToObjectByAttribute, binaryIndexSearch, daysIdToInt } from '../../GlobalFunctions'
import { computeOptionAssessmentColumns } from '../../utils/optionFunctions'



// Hook for managing a single transaction summary with summary
export const RefTransactionsSummaryTableHook = ({forcedVisualizationPeriodicity = null, onlyTable = false, onlySummary = false} = { onlyTable : false, onlySummary : false}) => {


  // Transactions
  // ----------------- 
  const exposures = ExposuresHook()
  const fwdCoverages = ForwardCoveragesHook()
  const options = OptionCoveragesHook()
  const accounts = AccountsHook()

  // Scenarios
  // ----------------
  const selectedScenarioID = RefApplicationParameterHook(con.SELECTED_SCENARIO)
  const scenarios = useSelector((state) => state[con.STORE][con.REDUCER_SCENARIOS])

  // Parameters
  // ----------------- 
  const spot = RefSPOT()
  const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)
  const modelParameters = AllModelParameterHook()
  const modelApplicationParameters = RefModelApplicationParameterHook()
  const periodicityVisualization = RefApplicationParameterHook(con.VISUALIZATION_PERIODICITY)
  const [localVisualizationPeriodicity, setLocalVisualizationPeriodicity] = useState(() => forcedVisualizationPeriodicity!= null ? forcedVisualizationPeriodicity:periodicityVisualization)

  // Loading Status for the transactions
  const allTransactionLoaded = RefApplicationParameterHook(con.ALL_TRANSACTIONS_LOADED)

  // Main Object
  const [transactionSummaryObject, setTransactionSummaryObject] = useState(() => [])

  // Summary Row
  const [summaryRow, setSummaryRow] = useState(() => {})
  
  // Computing Variable
  const [computing, setComputing] = useState(() => true)

  // Has summary table
  const [hasSummaryTable, setHasSummaryTable] = useState(() => false)


  // Assessment Options
  // ----------------- 
  // Inputs
  // Historical Volatility
  const hist_volatility =  RefMarketValueHook(con.MARKET_SUMMARIES)[con.MARKET_TOTAL_DAYS][con.VOLATILITY]

  // SOFR Curve
  const all_sofr_curves = RefMarketValueHook(con.ALL_SOFR_CURVES)

  // IBR 
  const all_IBR = RefMarketValueHook(con.ALL_IBR)
  
  // Volatility Surface
  useEffect(() => {
    fetchVolatilitySurfaceByDate("2024-04-23")   // TODO Cambiar cuando la DB se alimente
    formatDate(currentDate)
  }, [currentDate])
  const volatilitySurface = RefMarketValueHook(con.VOLATILITY_SURFACE)
  
  // GET CURRENT SOFR CURVE GIVEN A SPECIFIC REFERENCE DATE
  const [currentSofr, setCurrentSofr ] = useState(() => con.SOFR_DAYS.map(_ => 0.08))

  useEffect(() => {
  const index = binaryIndexSearch(currentDate,
      all_sofr_curves[con.DATES],
      false)

  if(index !== -1 && all_sofr_curves[con.VALUES].length > 0) {
      setCurrentSofr(con.SOFR_DAYS.map((col) => all_sofr_curves[con.VALUES][index][col]))
  }
  }, [all_sofr_curves, currentDate]);

  // GET CURRENT IBR CURVE GIVEN A SPECIFIC REFERENCE DATE
  const [currentIBR, setCurrentIBR ] = useState(() => con.IBR_DAYS.map(_ => 0.08))

  useEffect(() => {
      const index = binaryIndexSearch(currentDate,
          all_IBR[con.DATES],
          false)

      if(index !== -1 && all_IBR[con.VALUES].length > 0) {
          setCurrentIBR(con.IBR_DAYS.map((col) => all_IBR[con.VALUES][index][col]))
      }
  }, [all_IBR, currentDate]);

  // SOFR Function 
  const [sofrFunction, setSofrFunction] = useState(() => getGeneratorDevaluationFunctionByDay(con.SOFR_DAYS.map(daysIdToInt), currentSofr))

  useEffect(() => {
      setSofrFunction(() => getGeneratorDevaluationFunctionByDay(con.SOFR_DAYS.map(daysIdToInt), currentSofr))
  }, [currentSofr])
  

  // IBR Function
  const [ibrFunction, setIbrFunction] = useState(() => getGeneratorDevaluationFunctionByDay(con.IBR_DAYS.map(daysIdToInt), currentIBR))

  useEffect(() => {    
      setIbrFunction(() => getGeneratorDevaluationFunctionByDay(con.IBR_DAYS.map(daysIdToInt), currentIBR))
  }, [currentIBR])


  // Assessed Options Coverages
  const [optionCoverages, setOptionsCoverage] = useState(() => arrayToObjectByAttribute(Object.values(options).map((op) => computeOptionAssessmentColumns(op, currentDate, spot, hist_volatility, sofrFunction, ibrFunction, volatilitySurface))))


  // Updates Assessed Coverages
  useEffect(() => {

      let assessedArray = Object.values(options).map((op) => computeOptionAssessmentColumns(op, currentDate, spot, hist_volatility, sofrFunction, ibrFunction, volatilitySurface))

      // Sets Object
      setOptionsCoverage(arrayToObjectByAttribute(assessedArray))

  }, [options, currentDate, spot, hist_volatility, sofrFunction, ibrFunction, volatilitySurface])



  // Computing method
  const computeTransactionSummary = useCallback(
    () => {      

      // Only checks if all transactions have been loaded
      if(allTransactionLoaded)
      {                 
          new Promise(function (success) {
              
            setTimeout(async () => {
              
              // Function for updating the summary (async to not block execution)
              let [df_trans, sumRow] = await processTransactionsWithSummary(currentDate,
                                                        spot,
                                                        scenarios[selectedScenarioID].gen_fun,
                                                        modelParameters, 
                                                        exposures,
                                                        fwdCoverages,
                                                        optionCoverages,
                                                        accounts,
                                                        {...modelApplicationParameters, [con.VISUALIZATION_PERIODICITY] : localVisualizationPeriodicity})
              success([df_trans, sumRow])
            
            
            }, con.UI_TIMEOUT);
            
          }).then(([transSumm, sumRow]) => {

                 
            setTransactionSummaryObject(transSumm)
            setSummaryRow(sumRow)
            setHasSummaryTable(transSumm.length > 0)
            setComputing(false)

                                  
          })
    }
    },
    [allTransactionLoaded, exposures, fwdCoverages, optionCoverages, accounts, modelParameters, selectedScenarioID, localVisualizationPeriodicity, scenarios, currentDate, spot, modelApplicationParameters],
  )
                        
    // Reloads summary object on change
    useEffect(() => {
        
        // Computing
        setComputing(true)
        
        setTimeout(() => {   
          // Computes Transaction           
          computeTransactionSummary()
        }, con.UI_TIMEOUT);            

    }, [computeTransactionSummary])

    // Visualization
    useEffect(() => {
      
        if(forcedVisualizationPeriodicity == null)
           setLocalVisualizationPeriodicity(periodicityVisualization)

    }, [periodicityVisualization, forcedVisualizationPeriodicity])
    
    if(onlyTable)
      return([transactionSummaryObject, computing, hasSummaryTable])

    if(onlySummary)
      return([summaryRow, computing, hasSummaryTable])

    return([transactionSummaryObject, summaryRow, computing, hasSummaryTable])
}

