import { Button, FileInput, Radio, RadioGroup, Text } from '@blueprintjs/core'
// TODO - Documentar
import React, { useState } from 'react'
import { cropComments, parseUploadedFile } from '../../utils/importFunctions'
import * as con from "../../GlobalConstants"


// Style
import "../ComponentsGlobalStyle.css"
import { Account, CoverageFWD, CoverageSPOT, Exposure } from '../../store/actions/transactions'
import {  CoverageOption } from '../../store/actions/transactions'   // OCULTAR OPCIONES
import { StateApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { createNotification } from '../../templates/notifications/Notifications'
import Modal from '../../templates/popups/Modal'
import { downloadInputsTemplate } from '../../utils/reportFunctions'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import { filterObjectByKeys, sum } from '../../GlobalFunctions'
import { translateTransactions } from '../../utils/translateFunctions'


// Constants

const EMPTY_TRANS_DICT = {}

function TransactionsUpload() {

    // Client Type
    const clientType = RefModelParameterHook(con.CLIENT_TYPE)
    

    // Upload file
    const [uploadedFile, setUploadedFile] = StateApplicationParameterHook(con.UPLOADED_FILE)
    const [uploadingFile, setUploadingFile] = StateApplicationParameterHook(con.UPLOADING_FILE)


    // Options for uploading
    const [exposuresUploadParameter, setExposuresUploadParameter] = useState(() => con.APPEND)
    const [coveragesForwardUploadParameter, setCoveragesForwardUploadParameter] = useState(() => con.APPEND)
    const [coveragesSpotUploadParameter, setCoveragesSpotUploadParameter] = useState(() => con.APPEND)
    const [coveragesOptionUploadParameter, setCoveragesOptionUploadParameter] = useState(() => con.APPEND)     // OCULTAR OPCIONES
    const [accountsUploadParameter, setAccountsUploadParameter] = useState(() => con.REPLACE)

    // Internal Variables
    const [incorrectRecords, setIncorrectRecords] = useState(() => EMPTY_TRANS_DICT)
    const [correctRecords, setCorrectRecords] = useState(() => EMPTY_TRANS_DICT)
    const [withErrors, setWithErrors] = useState(() => false)


    const uploadTransactions = async () =>
    {
        setUploadingFile(true)

        let [correctTrans, incorrectTrans] = await parseUploadedFile(uploadedFile)
        
        // Filters by used transactions
        let usedTransactions = []
        if(exposuresUploadParameter !== con.IGNORE)
        usedTransactions.push(con.EXPOSURES)
        if(coveragesForwardUploadParameter !== con.IGNORE)
            usedTransactions.push(con.COVERAGES_FWD)
        if(coveragesSpotUploadParameter !== con.IGNORE)
            usedTransactions.push(con.COVERAGES_SPOT)
        if(coveragesOptionUploadParameter !== con.IGNORE)    // OCULTAR OPCIONES
            usedTransactions.push(con.COVERAGES_OPTION)             
        if(accountsUploadParameter !== con.IGNORE)
            usedTransactions.push(con.ACCOUNTS)           

        // Filters
        incorrectTrans = filterObjectByKeys(incorrectTrans, usedTransactions)
        
        // Checks for Errors
        let transWithErrors = sum(usedTransactions.map( k => incorrectTrans[k].length)) > 0 
        
        
        
        // Sets the values
        setIncorrectRecords(incorrectTrans)
        setCorrectRecords(correctTrans)

        setUploadedFile(null)
        setUploadingFile(false)

        setWithErrors(transWithErrors )

        // Only proceeds if no errors were found
        if(!transWithErrors)
            dispatchTransactions(correctTrans) 


    }

    const dispatchTransactions = (correctTrans) =>
    {
        // Checks for comment cropping
        let crop = cropComments(correctTrans)

        if(crop)
            createNotification(`El comentario de algunos registros fue recortado. Máximo tamaño admitido: ${con.MAX_COMMENT_LENGTH} caracteres`)


        // Does operation according to parameters
        // Exposures
        if(exposuresUploadParameter === con.APPEND)
            Exposure.bulkAdd(correctTrans[con.EXPOSURES])
        else if(exposuresUploadParameter === con.REPLACE)
            Exposure.replaceAll(correctTrans[con.EXPOSURES])

        // Forward Coverages
        if(coveragesForwardUploadParameter === con.APPEND)
            CoverageFWD.bulkAdd(correctTrans[con.COVERAGES_FWD])
        else if(coveragesForwardUploadParameter === con.REPLACE)
            CoverageFWD.replaceAll(correctTrans[con.COVERAGES_FWD])            

        // Spot Coverages
        if(coveragesSpotUploadParameter === con.APPEND)
            CoverageSPOT.bulkAdd(correctTrans[con.COVERAGES_SPOT])
        else if(coveragesSpotUploadParameter === con.REPLACE)
            CoverageSPOT.replaceAll(correctTrans[con.COVERAGES_SPOT])       

        // Option Coverages
        if(coveragesOptionUploadParameter === con.APPEND)                    // OCULTAR OPCIONES
            CoverageOption.bulkAdd(correctTrans[con.COVERAGES_OPTION])
        else if(coveragesOptionUploadParameter === con.REPLACE)
            CoverageOption.replaceAll(correctTrans[con.COVERAGES_OPTION])  

        // Accounts
        if(accountsUploadParameter === con.REPLACE)
            Account.replaceAll(correctTrans[con.ACCOUNTS])   

    }


    return (        
        <div>            
            <div className="columnParameterBox">                    
                <FileInput displayname="Buscar" text={ uploadedFile === null ? "Cargar Archivo..." : uploadedFile.name} onInputChange={(e) => {   
                    setUploadedFile(e.target.files[0])}} />
   
                <Button text="Descargar formato de datos" className="bp3-minimal" icon="download" onClick={(e) => downloadInputsTemplate(clientType)}/>
            </div>
            <Modal show={uploadedFile != null} doAccept={null} showCancel={false} doCancel={() => setUploadedFile(null)}>      
                <div >
                    <h3>Opciones de Carga</h3>
                    <div className="mediumParameterBox">
                        <RadioGroup label="Exposiciones" onChange={(e) => setExposuresUploadParameter(e.currentTarget.value)} selectedValue={exposuresUploadParameter}>
                            <Radio label="Agregar" value={con.APPEND} disabled={uploadingFile}/>
                            <Radio label="Remplazar" value={con.REPLACE} disabled={uploadingFile}/>
                            <Radio label="Ignorar" value={con.IGNORE} disabled={uploadingFile}/>
                        </RadioGroup>
                        <RadioGroup label="Coberturas FWD" onChange={(e) => setCoveragesForwardUploadParameter(e.currentTarget.value)} selectedValue={coveragesForwardUploadParameter}>
                            <Radio label="Agregar" value={con.APPEND} disabled={uploadingFile}/>
                            <Radio label="Remplazar" value={con.REPLACE} disabled={uploadingFile}/>
                            <Radio label="Ignorar" value={con.IGNORE} disabled={uploadingFile}/>
                        </RadioGroup>  
                        {/* OCULTAR OPCIONES    */}
                        <RadioGroup label="Coberturas Opciones" onChange={(e) => setCoveragesOptionUploadParameter(e.currentTarget.value)} selectedValue={coveragesOptionUploadParameter}>
                            <Radio label="Agregar" value={con.APPEND} disabled={uploadingFile}/>  
                            <Radio label="Remplazar" value={con.REPLACE} disabled={uploadingFile}/>
                            <Radio label="Ignorar" value={con.IGNORE} disabled={uploadingFile}/>
                        </RadioGroup>     
                        <RadioGroup label="Coberturas SPOT" onChange={(e) => setCoveragesSpotUploadParameter(e.currentTarget.value)} selectedValue={coveragesSpotUploadParameter}>
                            <Radio label="Agregar" value={con.APPEND} disabled={uploadingFile}/>
                            <Radio label="Remplazar" value={con.REPLACE} disabled={uploadingFile}/>
                            <Radio label="Ignorar" value={con.IGNORE} disabled={uploadingFile}/>
                        </RadioGroup>     
                        <RadioGroup label="Cuentas" onChange={(e) => setAccountsUploadParameter(e.currentTarget.value)} selectedValue={accountsUploadParameter}>
                            <Radio label="Agregar" value={con.APPEND} disabled/>
                            <Radio label="Remplazar" value={con.REPLACE} disabled={uploadingFile}/>
                            <Radio label="Ignorar" value={con.IGNORE} disabled={uploadingFile}/>
                        </RadioGroup>        
                    </div>
                    <div className="confirmCancelParameterBox">    
                    {
                        uploadingFile ? 
                            <Text> Cargando... </Text>:
                        <div>                                    
                            <Button icon="upload" text="Subir Flujos" onClick={uploadTransactions} />
                            <Button icon="cross" text="Cancelar" onClick={() => setUploadedFile(null)} />
                        </div>
                        }
                    </div>
                </div>
                
            </Modal> 

            <Modal show={withErrors} doAccept={null} showCancel={false} doCancel={() => { setUploadedFile(null);setCorrectRecords(EMPTY_TRANS_DICT);setIncorrectRecords(EMPTY_TRANS_DICT);setWithErrors(false)}}>      
                <div >
                    <h3>Errores Detectados</h3>
                    <Text>El archivo recibido contiene los siguientes errores:</Text>
                    <div className="compact" style={{marginLeft : "5%", marginRight : "5%", marginTop : "50px", marginBottom : "50px"}}>
                        {
                            Object.keys(incorrectRecords).map(transactionId =>
                                <ErrorSummary key={transactionId} transactionId={transactionId} errorArr={incorrectRecords[transactionId]} />
                            )                            
                        }
                    </div>
                    <Text>Si lo desea, puede continuar con la importación excluyendo los registros que tienen errores. De lo contrario, cancele la operación, corrija los errores mencionados y vuelva a internarlo. </Text>
                    <div className="confirmCancelParameterBox" style={{marginTop : "15px"}}>    
                    {
                        <div>                                    
                            <Button icon="upload" text="Subir Flujos" onClick={() => {dispatchTransactions(correctRecords);setWithErrors(false)}} />
                            <Button icon="cross" text="Cancelar" onClick={() => {setUploadedFile(null);setCorrectRecords(EMPTY_TRANS_DICT);setIncorrectRecords(EMPTY_TRANS_DICT);setWithErrors(false)}} />
                        </div>
                    }
                    </div>
                </div>
                
            </Modal> 
        </div>
    )
}


function ErrorSummary({transactionId, errorArr}) {

    return(

            errorArr.length > 0 ?
            <div className="compact" style={{textAlign : "left", marginTop : "5px"}}>
                <Text style={{fontWeight: 900}}>{ translateTransactions(transactionId)}</Text>
                {
                    [...Array(Math.min(3,errorArr.length)).keys()].map( i => 
                        <Text key={i} style={{paddingLeft : "10px"}}> - Linea {errorArr[i][con.ROW_NUMBER]}: {con.PARSING_ERROR_MESSAGES[errorArr[i][con.PARSED_ERROR_CODES][0]]} </Text>
                    )
                }
                {
                    errorArr.length > 3
                    ? <Text style={{paddingLeft : "20px"}}>( ... {errorArr.length - 3} error{errorArr.length - 3 > 1 ? "es":""} más.)</Text> 
                    : <></>
                }
            </div>
            : <></>
        
        
    )
}

export default TransactionsUpload
