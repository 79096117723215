import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from '@blueprintjs/core';
import IntradayRecordsTable from '../components/tables/IntradayRecordsTable';
import FirstLeftTable from '../components/tables/FirstLeftTable';
import SecondLeftTable from '../components/tables/SecondLeftTable';
import CandleStickChart from '../components/charts/CandleStickChart';
import closedMarketImage from '../img/code503-20.jpg'; 
import * as con from "./../GlobalConstants"
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';

const config = {
    headers : {
        "Authorization" : `Token ${con.tk_finance}`
    }
}


function IntradayDollarInfoPage() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchIntradayData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(con.rest_finance_url+con.intraday_dollar_info, config);

            if (response.data.market_status === 'closed') {
                setData('closed');
            } else {
                setData(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadExcel = () => {
        if (data && data.intraday_data) {
            const records = data.intraday_data.dates.map((date, index) => ({
                Fecha: DateTime.fromISO(date).toFormat('yyyy-MM-dd, hh:mm:ss a'),
                Max: data.intraday_data.high[index],
                Min: data.intraday_data.low[index],
                Cierre: data.intraday_data.close[index],
            }));

            const worksheet = XLSX.utils.json_to_sheet(records);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Intraday Data");

            XLSX.writeFile(workbook, 'Intraday_Data.xlsx');
        }
    };

    useEffect(() => {
        fetchIntradayData();
        document.body.style.overflow = 'hidden'; 
        document.body.style.margin = 0; 
        document.body.style.height = '100vh'; 
        return () => {
            document.body.style.overflow = '';
            document.body.style.margin = '';
            document.body.style.height = '';
        };
    }, []);

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (data === 'closed') {
        return (
            <div style={{ 
                position: 'fixed', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                overflow: 'hidden' 
            }}>
                <img 
                    src={closedMarketImage} 
                    alt="Market Closed" 
                    style={{ 
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'cover' 
                    }} 
                />
            </div>
        );
    }

    if (!data) {
        return <div>No se pudo cargar la información intradía del dólar.</div>;
    }

    return (
        <div className="gridBox" style={{ height: '100vh', display: 'flex', padding: '20px', boxSizing: 'border-box' }}>
            {/* Columna Izquierda */}
            <div style={{ flex: 6, marginRight: '20px', overflow: 'hidden' }}>
                <Button text="Actualizar" onClick={fetchIntradayData} style={{ marginBottom: '20px' }} />
                <FirstLeftTable data={data} />
                <SecondLeftTable data={data} />
                <CandleStickChart data={data.intraday_data} />
            </div>

            {/* Columna Derecha */}
            <div style={{ flex: 4, marginLeft: '20px', overflow: 'hidden' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginLeft: '10px' }}>
                    <Button
                        text="Alertas"
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)', 
                            color: 'black',
                            fontSize: '34px', 
                            lineHeight: '38px', 
                            fontWeight: 'bold', 
                            padding: '10px 35px', 
                        }}
                    />
                    <div><h2 style={{ textAlign: 'center' }}>RESUMEN MERCADO</h2></div>
                    <Button icon="download" text="Descargar Excel" onClick={handleDownloadExcel} />
                </div>
                {data.intraday_data ? (
                    <IntradayRecordsTable data={data.intraday_data} />
                ) : (
                    <div>No hay datos intradía disponibles.</div>
                )}
            </div>
        </div>
    );
}

export default IntradayDollarInfoPage;
