// TODO - Documentar
import React, { useEffect, useState } from 'react'
import * as con from "../../GlobalConstants"
import { EditApplicationParameterHook, StateApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { Button, Checkbox, Radio, RadioGroup } from '@blueprintjs/core'
import Search from '../search/Search'
import { formatDate, getToday } from '../../utils/dateFunctions'
import { setApplicationParameter } from '../../store/actions/applicationParameters'
import TransactionsUpload from './TransactionsUpload'
import TransactionsDelete from './TransactionsDelete'
import TransactionTableNavegation from './TransactionTableNavegation'
import { translateTransactions } from '../../utils/translateFunctions'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import TransactionsDownload from './TransactionsDownload'


function TransactionsControl() {



    // Selected Transaction
    const [selectedTransactions, setSelectedTransactions] = StateApplicationParameterHook(con.SELECTED_TRANSACTIONS)

    // Client Type
    const clientType = RefModelParameterHook(con.CLIENT_TYPE)
    // Add new Row
    const setAddingNewRow = EditApplicationParameterHook(con.ADDING_NEW_ROW)
    

    // Loading
    const setLoadingTransactions = EditApplicationParameterHook(con.LOADING_CURRENT_TRANSACTIONS)


    // Filters
    const [includeActive, setIncludeActive] = StateApplicationParameterHook(con.INCLUDE_ACTIVE)
    const [includeExpired, setIncludeExpired] = StateApplicationParameterHook(con.INCLUDE_EXPIRED)
    const [includePrepay, setIncludePrepay] = StateApplicationParameterHook(con.INCLUDE_PREPAY)
    const [includePurchases, setIncludePurchases] = StateApplicationParameterHook(con.INCLUDE_PURCHASES)
    const [includeSales, setIncludeSales] = StateApplicationParameterHook(con.INCLUDE_SALES)



    // Disabled Filters
    const [canIncludeActive, setCanIncludeActive] = useState(() => true)
    const [canIncludeExpired, setCanIncludeExpired] = useState(() => true)
    const [canIncludePrepay, setCanIncludePrepay] = useState(() => true)
    const [canIncludePurchases, setCanIncludePurchases] = useState(() => true)
    const [canIncludeSales, setCanIncludeSales] = useState(() => true)
    const [canAddTransaction, setCanAddTransaction] = StateApplicationParameterHook(con.CAN_ADD_TRANSACTION)
    



    // Search
    // Search
    const searchOptions = [{[con.ID] : con.COMMENT,
                            [con.TYPE] : con.TEXT,
                            [con.NAME] : "Comentario"
                          },
                          {[con.ID] : con.EXPIRATION_DATE,
                            [con.TYPE] : con.DATE,
                            [con.DEFAULT_VALUE] : formatDate(getToday()),
                            [con.NAME] : "Fecha de Vto."
                          },
                          {
                            [con.ID] : con.AMOUNT,
                            [con.TYPE] : con.MONEY,
                            [con.NAME] : "Monto",
                            [con.CURRENCY] : con.MONEY_USD
                          }]

  useEffect(() => {
      setApplicationParameter(con.INCLUDE_ACTIVE, true)
      setApplicationParameter(con.INCLUDE_EXPIRED, true)
      setApplicationParameter(con.INCLUDE_PREPAY, true)
      setApplicationParameter(con.INCLUDE_PURCHASES, true)
      setApplicationParameter(con.INCLUDE_SALES, true)

  }, [selectedTransactions])

  useEffect(() => {

    if(selectedTransactions === con.EXPOSURES)
    {
      setCanIncludeActive(true)
      setCanIncludeExpired(true)
      setCanIncludePrepay(true)
      setCanIncludePurchases(false)
      setCanIncludeSales(false)
      setCanAddTransaction(true)

    }
    else if(selectedTransactions === con.COVERAGES_FWD)
    {
      setCanIncludeActive(true)
      setCanIncludeExpired(true)
      setCanIncludePrepay(false)
      setCanIncludePurchases(true)
      setCanIncludeSales(true)
      setCanAddTransaction(true)


    }
    else if(selectedTransactions === con.COVERAGES_SPOT)
    {
      setCanIncludeActive(false)
      setCanIncludeExpired(false)
      setCanIncludePrepay(false)
      setCanIncludePurchases(false)
      setCanIncludeSales(false)
      setCanAddTransaction(true)

    }
    else if(selectedTransactions === con.COVERAGES_OPTION)
    {
      setCanIncludeActive(true)
      setCanIncludeExpired(true)
      setCanIncludePrepay(false)
      setCanIncludePurchases(true)
      setCanIncludeSales(true)
      setCanAddTransaction(true)

    }
    else if(selectedTransactions === con.ACCOUNTS)
    {
      setCanIncludeActive(false)
      setCanIncludeExpired(false)
      setCanIncludePrepay(false)
      setCanIncludePurchases(false)
      setCanIncludeSales(false)
      setCanAddTransaction(false)
    }

    setLoadingTransactions(true)
    setTimeout(() => {              
      setLoadingTransactions(false)
    }, con.LOADING_TRANSACION_TIMEOUT);


  }, [selectedTransactions, setCanAddTransaction, setLoadingTransactions])


  useEffect(() => {
    
    setApplicationParameter(con.FILTER_FUN, (ob) => {

                  let show = true;            
                  if(ob[con.STATE] === con.EXPIRED)
                    show = show && includeExpired
                  if(ob[con.STATE] === con.ACTIVE)
                    show = show && includeActive
                  if(ob[con.STATE] === con.PREPAYED)
                    show = show && includePrepay
                  if(ob[con.COVERAGE_TYPE] === con.BUY)
                    show = show && includePurchases
                  if(ob[con.COVERAGE_TYPE] === con.SELL)
                    show = show && includeSales 
                  
                  return show
              })
              
  }, [selectedTransactions, includeExpired, includeActive, includePrepay, includePurchases, includeSales])
  
    
  

  

  return (
    <div>
    
      <div className="bigParameterBoxTop compact">
          <div>
            <div>
              <h4>Flujo Actual</h4>
              <div className="compact" style={{textAlign : "left"}}>             
                <RadioGroup              
                  onChange={(e) => setSelectedTransactions(e.currentTarget.value)}
                  selectedValue={selectedTransactions}
                  inline={false}>
                    <Radio label={translateTransactions(con.EXPOSURES, clientType)} value={con.EXPOSURES} />
                    <Radio label={translateTransactions(con.COVERAGES_FWD, clientType)} value={con.COVERAGES_FWD} />
                    {/* OCULTAR OPCIONES */}
                    <Radio label={translateTransactions(con.COVERAGES_OPTION, clientType)} value={con.COVERAGES_OPTION} /> 
                    <Radio label={translateTransactions(con.COVERAGES_SPOT, clientType)} value={con.COVERAGES_SPOT} />
                    <Radio label={translateTransactions(con.ACCOUNTS, clientType)} value={con.ACCOUNTS} />
              </RadioGroup>
            </div>
          </div>
          </div>
          <div>
            <h4>Filtrar</h4>
            <div className="columnParameterBoxLeft compact">            
                <Checkbox checked={includeActive} label="Activas" onChange={() => setIncludeActive(!includeActive)} disabled={!canIncludeActive}/>
                <Checkbox checked={includeExpired} label="Vencidas" onChange={() => setIncludeExpired(!includeExpired)} disabled={!canIncludeExpired}/>
                <Checkbox checked={includePrepay} label="Prepagadas" onChange={() => setIncludePrepay(!includePrepay)} disabled={!canIncludePrepay}/>              
                <Checkbox checked={includePurchases} label="Compras" onChange={() => setIncludePurchases(!includePurchases)} disabled={!canIncludePurchases}/>              
                <Checkbox checked={includeSales} label="Ventas" onChange={() => setIncludeSales(!includeSales)} disabled={!canIncludeSales}/>              
            </div>
          </div>
          <div>
            <h4>Buscar</h4>
            <div className="columnParameterBox">
                <Search searchOptions={searchOptions}/>
            </div>
          </div>
          <div>
            <h4>Agregar</h4>
              <div className="columnParameterBox">            
                  <TransactionsUpload />
                  <div className="smallParameterBox">
                      <Button text="Agregar un Registro"  icon="plus" onClick={(e) => setAddingNewRow(true)} disabled={!canAddTransaction}/>
                  </div>
              </div>
            </div>            
            <div>
            <h4>Eliminar</h4>    
              <TransactionsDelete/>
            </div>
            <div>
            <h4>Descargar</h4>    
              <TransactionsDownload/>
            </div>
            

      </div>
      <div>
        <h3>{translateTransactions(selectedTransactions, clientType)}</h3>
        <TransactionTableNavegation />
      </div>

    </div>
  )
}

export default TransactionsControl