// TODO - Documentar
import React from 'react';
import './App.css';
import { useEffect } from 'react'


// Actions
import { fetchAllMarketData } from './store/actions/market';
import { getModelScenarios } from './store/actions/scenarios';
import {fetchModelParameters} from './store/actions/modelParameters';

// Global constants
import * as con from "./GlobalConstants"

import { updateSpot } from './utils/marketFunctions';

import LoginPage from './pages/LoginPage';
import GlobalView from './pages/GlobalView';
import ScenariosPage from './pages/ScenariosPage';
import InputsPage from './pages/InputsPage';
import CurrentPositionPage from './pages/CurrentPositionPage';
import CustomTickerTape from './components/tradingViewComponentes/CustomTickerTape';
import RiskComparison from './pages/RiskComparison';
import { checkServer } from './store/actions/applicationParameters';
import CoverageProfilePage from './pages/UserInformationPage';
import BandPositionPage from './pages/BandPositionPage';
import BandComparisonPage from './pages/BandComparisonPage';
import ReportsPage from './pages/ReportsPage';
import Nav from './components/navegation/Nav';
import { Account, CoverageFWD, CoverageSPOT, CoverageOption, Exposure, resetAllTransactions } from './store/actions/transactions';
import * as preventBackspace from "prevent-backspace/index";
import MarketsTechnicalAnalysisPage from './pages/MarketsTechnicalAnalysisPage';
import MarketsFundamentalAnalysis from './pages/MarketsFundamentalAnalysis';
import HistoricalDataPage from './pages/HistoricalDataPage';
import IntradayDollarInfoPage from './pages/IntradayDollarInfoPage';
import { IsStaff, RefSurrogateUser, RefToken} from './components/hooks/AuthenticationHook';
import { RefCurrentPage } from './components/hooks/NavegationHook';
import { fetchSurrogateUserList, fetchUserAttributes, fetchUserLines } from './store/actions/authentication';
import { RefApplicationParameterHook } from './components/hooks/ApplicationParametersHooks';
import Notifications, { createError } from './templates/notifications/Notifications';
import { LatestErrorsHook } from './components/hooks/ErrorsHook';
import CalculatorPage from './pages/CalculatorPage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import { checkIfMobile } from './store/actions/mobile';
import MobileLoginPage from './pagesMobile/MobileLoginPage';
import UserProfilePage from './pages/UserProfilePage';
import { fetchAdvisors } from './store/actions/advisors';
import WhatsAppIcon from './components/whatsApp/WhatsAppIcon';
import { RefSelectedAdvisors } from './components/hooks/AdvisorsHooks';
import CoveragesGridPage from './pages/CoveragesGridPage';
import ForwardAssessmentPage from './pages/ForwardAssessmentPage';
import OptionAssessmentPage from './pages/OptionsAssessmentPage';
import { fetchUserLineApplicationParameters } from './store/actions/userLineApplicationParameters';
import InactivityCheck from './components/listeners/InactivityCheck';
import MarketsOrionOverview from './pages/MarketsOrionOverview';                // OCULTAR Orion     
import MobileNav from './components/navegation/MobileNav';
import MobileGlobalView from './pagesMobile/MobileGlobalView';
import MobileCalculatorPage from './pagesMobile/MobileCalculatorPage';
import MobileForwardAssessmentPage from './pagesMobile/MobileForwardAssessmentPage';
import MobileCoveragesGridPage from './pagesMobile/MobileCoveragesGridPage';
import MobileReportsPage from './pagesMobile/MobileReportsPage';
import MobileMarketsTechnicalAnalysisPage from './pagesMobile/MobileMarketsTechnicalAnalysisPage';
import MobileMarketsFundamentalAnalysis from './pagesMobile/MobileMarketsFundamentalAnalysis';
import MobileMarketsOrionOverview from './pagesMobile/MobileMarketsOrionOverview';
import MobileCurrentPositionPage from './pagesMobile/MobileCurrentPositionPage';
import MobileLoginPageNotSupported from './pagesMobile/MobileLoginPageNotSupported';


function App() {
  
  // Is Mobile
  const isMobile = RefApplicationParameterHook(con.IS_MOBILE)
  const isMobileEnabled = RefApplicationParameterHook(con.IS_MOBILE_ENABLED)
  

  // Authentication
  // -----------------
  const isStaff = IsStaff()
  const surrogateUser = RefSurrogateUser()
  const token = RefToken()

  // App ready
  const appReady = RefApplicationParameterHook(con.APP_READY)

  // Selected User Line
  const selectedUserLine = RefApplicationParameterHook(con.SELECTED_LINE)

  // Navigation
  const currentPage = RefCurrentPage(); 

  // Errors Hook
  const latestError = LatestErrorsHook()

  // Server
  const server = RefApplicationParameterHook(con.SERVER)

  // const spotScheme = RefApplicationParameterHook(con.SPOT_SCHEME)

  // Advisor
  const advisors = RefSelectedAdvisors()

  // Mobile application
  useEffect(() => {
  
    checkIfMobile()
    window.addEventListener('resize', checkIfMobile);
  
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    }
  }, [appReady])


  // Server Check
  useEffect(() => {

    preventBackspace()
    
    // Check Server
    checkServer()


  }, []) 

  

  // Loads info if server check and every time the token is refreshed
  useEffect(() => {

    if(server[con.STATUS] === con.OK)
    {
      // Get scenarios
      getModelScenarios()

      // Gets all the market Data
      fetchAllMarketData()

    }


  }, [server, token]) 



  // // Spot Extraction LEGACY REFRESH AUTOMATIC REFRESH SPOT
  // useEffect(() => {

  //   let spotInterval = getApplicationParameter(con.SPOT_SEARCH_INTERVAL)
  //   if(spotInterval !== null)
  //       clearInterval(spotInterval)

  //   if(appReady && spotScheme === con.AUTOMATIC)
  //   {
  //     updateSpot()

  //     spotInterval = setInterval(() => {
        
  //       if(appReady && spotScheme === con.AUTOMATIC)
  //           updateSpot()

          
  //     }, con.SPOT_INTERVAL_CHECK_MS);

  //     Sets Interval
  //     setApplicationParameter(con.SPOT_SEARCH_INTERVAL, spotInterval)

  //   }
        

  //   return() => {
  //     clearInterval(getApplicationParameter(con.SPOT_SEARCH_INTERVAL))
  //   }
    
  // }, [spotScheme, appReady])


  


  // Loads Transactions
  useEffect(() => {

    // Get Transactions
    if(appReady)
    {

      // Current Spot
      updateSpot()

      // User Lines
      fetchUserLines()

      // User Attributes
      fetchUserAttributes()
      
      // Advisors 
      fetchAdvisors()
      
      // Surrogate list
      if(isStaff)
        fetchSurrogateUserList()
      
            

    }

  }, [appReady, isStaff]) 


  // Surrogate User
  // Loads transactions
  useEffect(() => {

    
    // Get Transactions
    if(appReady && 
      isStaff &&
      surrogateUser != null)
    {
      // Transactions
      resetAllTransactions()

      // User Attributes
      fetchUserAttributes()

      // User Lines
      fetchUserLines()

    }
    

  }, [appReady, surrogateUser, isStaff]) 



  // Loads the selected line
  useEffect(() => {

    if(appReady && selectedUserLine !== null)
    {

        // Model Parameters
        fetchModelParameters()

        // User Line Application Parameters
        fetchUserLineApplicationParameters()

        // Transactions
        Exposure.fetch();
        CoverageFWD.fetch();
        CoverageSPOT.fetch();
        CoverageOption.fetch();
        Account.fetch();
        
        // Annotations
        Exposure.fetchAnnotations();
        CoverageFWD.fetchAnnotations();
        CoverageSPOT.fetchAnnotations();
        CoverageOption.fetchAnnotations();
        Account.fetchAnnotations();

    }

  }, [selectedUserLine, appReady])
  



  // Errors
  
  useEffect(() => {
    
    if(latestError != null)
    {
        createError(latestError[con.MESSAGE])
    }

  }, [latestError])

  
  
  // Uses a function through switch to render
  const renderDesktopComponents = (page) =>{

      switch (page) {
            case con.PAGE_START:
              return(<GlobalView/>)
            case con.PAGE_COVERAGE_PROFILE:
                return(<CoverageProfilePage/>)                
            case con.PAGE_SCENARIOS:
                return(<ScenariosPage/>)          
            case con.PAGE_MARKETS_TECHNICAL_ANALYSIS:
                return(<MarketsTechnicalAnalysisPage/>)     
            case con.PAGE_MARKETS_FUNDAMENTAL_ANALYSIS:
                return(<MarketsFundamentalAnalysis/>)    
            case con.PAGE_HISTORICAL_DATA:
                return (<HistoricalDataPage />);
            case con.PAGE_INTRADAY_DOLLAR_INFO:
                return (<IntradayDollarInfoPage />);
            case con.PAGE_INPUTS:
                return(<InputsPage/>)   
            case con.PAGE_CURRENT_POSITION:
                return(<CurrentPositionPage/>)
            case con.PAGE_RISK_COMPARISON:
                return(<RiskComparison/>)
            case con.PAGE_BAND_POSITION:
                return(<BandPositionPage/>)
            case con.PAGE_BAND_COMPARISON:
                return(<BandComparisonPage/>)
            case con.PAGE_REPORTS:
                return(<ReportsPage/>)  
            case con.PAGE_COVERAGES_GRID:
                return(<CoveragesGridPage />)
            case con.PAGE_CALCULATOR:
                return(<CalculatorPage/>)
            case con.PAGE_ADMIN_DASHBOARD:
                return(<AdminDashboardPage/>)
            case con.PAGE_USER_PROFILE:
                return(<UserProfilePage/>)
            case con.PAGE_FORWARD_ASSESSMENT:
                return(<ForwardAssessmentPage/>)
            case con.PAGE_OPTION_ASSESSMENT:
                return(<OptionAssessmentPage/>)          
            case con.PAGE_MARKET_ORION_OVERVIEW:       // OCULTAR Orion     
                return(<MarketsOrionOverview/>)       // OCULTAR Orion                  
            default:
              return(<GlobalView/>);
      }
  }



  
  // Uses a function through switch to render
  const renderMobileComponents = (page) =>{

    if(!isMobileEnabled)
      return(<MobileLoginPageNotSupported />)

    switch (page) {
          case con.PAGE_START:
            return(<MobileGlobalView/>  )
          case con.PAGE_CALCULATOR:
              return(<MobileCalculatorPage/>)   
          case con.PAGE_FORWARD_ASSESSMENT:
              return(<MobileForwardAssessmentPage/>) 
          case con.PAGE_COVERAGES_GRID:
              return(<MobileCoveragesGridPage />)      
          case con.PAGE_REPORTS:
              return(<MobileReportsPage />) 
          case con.PAGE_MARKETS_TECHNICAL_ANALYSIS:
                return(<MobileMarketsTechnicalAnalysisPage/>)     
          case con.PAGE_MARKETS_FUNDAMENTAL_ANALYSIS:
                return(<MobileMarketsFundamentalAnalysis/>)
          case con.PAGE_HISTORICAL_DATA:
                return (<HistoricalDataPage />);   
          case con.PAGE_INTRADAY_DOLLAR_INFO:
                return (<IntradayDollarInfoPage />);
          case con.PAGE_MARKET_ORION_OVERVIEW:
                return(<MobileMarketsOrionOverview/>)       
          case con.PAGE_CURRENT_POSITION:
                return(<MobileCurrentPositionPage/>)                                               
          default:
            return(<MobileGlobalView/>);
    }
}



  return (
    
    
    <div className="App bp3-dark">  
      <InactivityCheck/>       
        {          
        server[con.STATUS] === con.NOT_STARTED || server[con.STATUS] === con.LOADING 
          ? <div style={{height : "100%"}}></div> 
          : isMobile
          // Mobile
          ? <>
            {
              ! isMobileEnabled ? <MobileLoginPageNotSupported />
              : !appReady 
                ? <MobileLoginPage/> 
                : <div>
                    <MobileNav/>
                    <div style={{height : `${con.MOBILE_NAV_BAR}vh`}}></div>
                    <div className="mainPageWrapper" >{renderMobileComponents(currentPage)} </div>            
              
                    {advisors.length > 0  && <WhatsAppIcon />}
                  </div> 
                
            }
            </>
          // Desktop
          : <>
           {
            !appReady 
              ? <LoginPage/> 
              : <div>
                  <Nav/>      
                  <div style={{height : `${con.NAV_BAR}vh`}}></div>                       
                  <CustomTickerTape />                    

                  <div className="mainPageWrapper" >{renderDesktopComponents(currentPage)} </div>
                  {advisors.length > 0  && <WhatsAppIcon />}
                </div> 
            }
            </>
         }
    
    <Notifications/>          
      </div>
  );
}

export default App;
