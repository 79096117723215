import React from 'react';
import { CellText } from '../../templates/tables/Cells';

function FirstLeftRow({ data }) {
    return (
        <div className="flex-fila" style={{ padding: '10px 20px', textAlign: 'center', whiteSpace: 'pre-line' }}>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={`${data.apertura.split(' ')[0]}\n(${data.apertura.split(' ')[1]})`} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={`${data.ultimo.split(' ')[0]}\n(${data.ultimo.split(' ')[1]}, ${parseFloat(data.ultimo.split(' ')[2]).toFixed(2)}%)`} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={`${data.promedio.split(' ')[0]}\n(${parseFloat(data.promedio.split(' ')[1]).toFixed(2)}%)`} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={`${data.maximo.split(' ')[0]}\n(${data.maximo.split(' ')[1]})`} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={`${data.minimo.split(' ')[0]}\n(${data.minimo.split(' ')[1]})`} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: '10px' }}>
                <CellText value={`${data.volumen.split(' ')[0]}\n(TRM: ${data.volumen.split(' ')[1]})`} editing={false} />
            </div>
        </div>
    );
}

export default FirstLeftRow;
