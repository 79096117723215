// TODO - Documentar
import React, { useEffect, useState } from 'react'
import {StaticTable} from '../../templates/tables/StaticTable'

import * as con from "../../GlobalConstants"
import * as globalFun from "../../GlobalFunctions"

// Style
import "./../ComponentsGlobalStyle.css"
import StatusIconSmall from '../../templates/loading/StatusIconSmall'
import {  RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { getLine, getUserName } from '../../store/actions/authentication'
import { TransactionColumnsHook } from '../hooks/TransactionsHooks'
import { buildDefaultRow } from '../../utils/transactionFunctions'
import { AllSurrogateObjectiveBands, AllSurrogateTransactionsExpiryDates } from '../hooks/SurrogateTransactionsHooks'
import { BandSelect, ScenariosSelect } from '../parameters/SingleParameters'
import { Button } from '@blueprintjs/core'
import { fetchAllSurrogateTransactions } from '../../store/actions/surrogateTransactions'
import { getMainAdvisorName } from '../../store/actions/advisors'
import Modal from '../../templates/popups/Modal'
import AdminUserLineComments from './AdminUserLineComments'



export const AdminDashboardTableSummary = ({selectedColumns, computing, allSurrogateTransactionsSummaries}) => {



    // Transactions loaded
    const surrogateLoading = RefApplicationParameterHook(con.SURROGATE_TRANSACTIONS_LOADED)
    const allTransactionsLoaded = RefApplicationParameterHook(con.ALL_SURROGATE_TRANSACTIONS_LOADED)

    const transactionsCol = TransactionColumnsHook(selectedColumns, false)

    // Surrogate User Dict
    const surrogateUserDict = RefApplicationParameterHook(con.SURROGATE_USER_DICT)

    // Expiry Column
    const expiryDays = AllSurrogateTransactionsExpiryDates()

    // Objective Band
    const objectiveBands = AllSurrogateObjectiveBands()
    
    // Comments
    const [viewComments, setViewComments] = useState(() => false)

    //Selected Summary Line
    const [selectedLineSummary, setSelectedLineSummary] = useState(() => null)

    // Table Data
    const [tableData, setTableData] = useState(() => constructTableData(allSurrogateTransactionsSummaries, expiryDays, objectiveBands, transactionsCol))

    useEffect(() => {
      if(!computing)
        setTableData(constructTableData(allSurrogateTransactionsSummaries, expiryDays, objectiveBands, transactionsCol))      
    
    }, [computing,allSurrogateTransactionsSummaries, transactionsCol, expiryDays, objectiveBands ])
    
    useEffect(() => {
    
    }, [selectedLineSummary])
    

    return (
        <div style={{marginBottom : "2vh"}}>

                <Modal show={viewComments} doAccept={null} doCancel={() => setViewComments(false)} widthPercentage={75}>
                    { selectedLineSummary !== null ? <AdminUserLineComments selectedLineSummary={selectedLineSummary} /> : <div></div>}
                </Modal>

              <div className="gridBox" style={{ display : 'flex', justifyContent : 'space-around'}}>
                <div style={{}}>
                  <div className="columnParameterBox">                  
                    <Button icon="refresh" text="Refrescar" onClick={() => fetchAllSurrogateTransactions()}/>
                  </div>
                </div>
                <div style={{}}>  
                  <ScenariosSelect />   
                </div>     
                <div style={{}}>  
                  <BandSelect />
                </div>        
             </div>
            {
            
            
            // If still loading from DB  
            !allTransactionsLoaded ?
              <div>
                <h4>Extrayendo las transacciones de la base de datos.</h4>
                <div className="mediumParameterBox">
                  <StatusIconSmall status={surrogateLoading[con.MODEL_PARAMETERS][con.STATUS]} title={"Parámetros"}/> 
                  <StatusIconSmall status={surrogateLoading[con.EXPOSURES][con.STATUS]} title={"Exposiciones"}/>
                  <StatusIconSmall status={surrogateLoading[con.COVERAGES_FWD][con.STATUS]} title={"Coberturas FWD"}/>                                       
                  <StatusIconSmall status={surrogateLoading[con.COVERAGES_SPOT][con.STATUS]} title={"Coberturas SPOT"}/> 
                  <StatusIconSmall status={surrogateLoading[con.ACCOUNTS][con.STATUS]} title={"Cuentas"}/> 
                </div>
              </div>
             : computing ? <h4>Computando</h4> : 
             <div>
              <StaticTable columnArray={transactionsCol} 
                              dataDictionary={tableData}
                              compress={true}
                              smallFont={true}
                              filterFunction={(tr) => globalFun.allDashboardEnabledUsers(surrogateUserDict[tr[con.USER_ID]])}
                              onclickFunction={(rowData) => {

                                setSelectedLineSummary(rowData)
                                setViewComments(true)

                              }}
                              />
              </div>
            }

        </div>
    )
}

AdminDashboardTableSummary.defaultProps = {
  selectedColumns: [{ [con.ID] : con.USERNAME},
                    { [con.ID] : con.ADVISOR},
                    { [con.ID] : con.LINE},
                    { [con.ID] : con.EXPOSURE_AMOUNT},
                    { [con.ID] : con.COVERAGE_AMOUNT},                      
                    { [con.ID] : con.RATE},
                    { [con.ID] : con.COVERAGE_PERCENTAGE} ,
                    { [con.ID] : con.POSITION_IN_BANDS},
                    { [con.ID] : con.OBJECTIVE_BAND},
                    { [con.ID] : con.OBJECTIVE_BAND_PERCENTAGE },
                    { [con.ID] : con.VAR},
                    { [con.ID] : con.EXPIRY_DAYS},
                    { [con.ID] : con.LAST_EDITED}]                  
};



const constructTableData = (transSummary, expiryDays, objectiveBands, transactionsCol) =>
{
  let defaultRow = buildDefaultRow(transactionsCol)
  let tableData = []
  Object.keys(transSummary).forEach(userId =>
    {
      Object.keys(transSummary[userId]).forEach(lineId => {
          let line = getLine(userId, lineId)
          let ob = {...defaultRow,
                    [con.ID] : tableData.length,
                    [con.USERNAME] : getUserName(userId),
                    [con.ADVISOR] : getMainAdvisorName(userId),
                    [con.USER_ID] : parseInt(userId),
                    [con.LINE] : line[con.NAME],
                    [con.LINE_ID] : lineId,
                    [con.LAST_EDITED] : line[con.LAST_EDITED],
                    ...transSummary[userId][lineId][con.SUMMARY_ROW],
                    ...objectiveBands[userId][lineId],
                    [con.EXPIRY_DAYS] : expiryDays[userId][lineId]}                
          tableData.push(ob)
      })
    })

    // Convert to dictionary
    tableData = Object.assign({}, ...tableData.map((x,i) => ({[i]: x})));

    return(tableData)

}

