import React from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import { useSelector } from 'react-redux';
import FirstLeftRow from './FirstLeftRow';

function FirstLeftTable({ data }) {
    const spot = useSelector(state => state['store']['applicationParameters']['spot']);

    return (
        <div className="contenedor-tabla" style={{ fontSize: '18px', marginBottom: '20px', padding: '0 20px' }}>
            <div className="flex-tabla">
                <div className="flex-fila header-row" style={{ padding: '10px 20px' }}>
                    <div className="flex-celda">APERTURA</div>
                    <div className="flex-celda">ÚLTIMO</div>
                    <div className="flex-celda">PROMEDIO</div>
                    <div className="flex-celda">MAX</div>
                    <div className="flex-celda">MIN</div>
                    <div className="flex-celda">VOLUMEN</div>
                </div>
            </div>

            <div className="flex-tabla">
                <FirstLeftRow 
                    data={{
                        apertura: `${data.apertura.toFixed(2)} ${data.apertura_time}`,
                        ultimo: `${spot} ${data.ultimo_time} ${data.ultimo_percentage}`,
                        promedio: `${data.promedio.toFixed(2)} ${data.promedio_percentage}`,
                        maximo: `${data.maximo} ${data.max_time}`,
                        minimo: `${data.minimo} ${data.min_time}`,
                        volumen: `${data.volumen} ${data.trm_volumen.toFixed(2)}`
                    }}
                />
            </div>
        </div>
    );
}

export default FirstLeftTable;
