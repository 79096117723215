// Component for All parameters
// TODO - Documentar
import React from 'react';



// Style
import '../ComponentsGlobalStyle.css'
import { IsStaff, RefUser } from '../hooks/AuthenticationHook';

// Global constants
import * as con from '../../GlobalConstants'

import { AverageForPercentage, BandSelect, CarryOverCoverage, NonZeroRowsCheckBox, PassThrough, ScenariosSelect, VisualizationPeriodicity } from './SingleParameters';


export const CurrentPositionParameters = () => {

  const isStaff = IsStaff()

  const user = RefUser()
    return (
    <div>
        <div className='mediumParameterBox' >                                      
            <AverageForPercentage />
            <PassThrough />
            { isStaff && user[con.USERNAME] !== "carlos" ? <CarryOverCoverage /> : <></>}
            <NonZeroRowsCheckBox />                      
            <VisualizationPeriodicity/>              
        </div>
        <div className="gridBox" style={{ paddingLeft : '15px', paddingRight : '15px'}}>
           <div style={{paddingLeft : "43%"}}>  
              <ScenariosSelect />   
            </div>        
            <div style={{paddingLeft : "17%"}}>  
              <BandSelect/>
            </div>
        </div>
      </div>
    )
}



export const CurrentPositionParametersMobile = () => {


    return (
    <div>
        <div className='columnParameterBox' >                                                
            <AverageForPercentage />
            <ScenariosSelect />
            <BandSelect/>             
        </div>
      </div>
    )
}