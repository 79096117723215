import React from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import SecondLeftRow from './SecondLeftRow';
import { useSelector } from 'react-redux';

function SecondLeftTable({ data }) {
    const spot = useSelector(state => state['store']['applicationParameters']['spot']);
    const diffCierreAnterior = spot - data.cierre_anterior;
    const volatilidadJornada = spot - data.apertura;
    const variacionSemanal = spot - data.primer_registro_semana;
    const variacionSemanalPorcentaje = ((spot - data.primer_registro_semana) / data.primer_registro_semana) * 100;

    return (
        <div className="contenedor-tabla" style={{ fontSize: '18px', padding: '0 20px' }}>
            <div className="flex-tabla">
                <div className="flex-fila header-row" style={{ padding: '10px 20px' }}>
                    <div className="flex-celda">CIERRE ANTERIOR</div>
                    <div className="flex-celda">DIF. CIERRE ANTERIOR</div>
                    <div className="flex-celda">TRM(HOY)</div>
                    <div className="flex-celda">VOLATILIDAD JORNADA</div>
                    <div className="flex-celda">VARIACIÓN SEMANAL</div>
                    <div className="flex-celda">INDICADOR AGOTAMIENTO</div>
                </div>
            </div>

            <div className="flex-tabla">
                <SecondLeftRow 
                    data={{
                        cierreAnterior: data.cierre_anterior,
                        diffCierreAnterior: diffCierreAnterior.toFixed(2),
                        trmReal: data.trm_real,
                        volatilidadJornada: volatilidadJornada.toFixed(2),
                        variacionSemanal: variacionSemanal.toFixed(2),
                        variacionSemanalPorcentaje: variacionSemanalPorcentaje,
                        indicadorAgotamiento: data.indicador_agotamiento.toFixed(2)
                    }}
                />
            </div>
        </div>
    );
}

export default SecondLeftTable;
