// Report Functions

// Documentar
// Global constants
import * as con from "../GlobalConstants"

import axios from 'axios';
import store from '../store/store';
import { handleRequestError } from "./errorFunctions";
import { getApplicationParameter, getModelApplicationParameters, setApplicationParameter } from "../store/actions/applicationParameters";
import { getFinanceRequestConfig, getRequestConfig, getSurrogateUser, getUser, getVisualizedUser, surrogateActive } from "../store/logic/supportFunctions";
import { processTransactionsWithSummary } from "./transactionFunctions";
import { deleteAttributeFromObject } from "../GlobalFunctions";
import { downloadFileFromResponse } from "./downloadFunctions";
import { createNotification } from "../templates/notifications/Notifications";
import { formatDate, getToday } from "./dateFunctions";


export const makePurchaseReport = ({startDate, endDate, includePurchases, operation}) =>
{   
    setApplicationParameter(con.PURCHASE_REPORT, {[con.GENERATING] : true,
                                                    [con.REPORT_FILE_NAME] : null}) 
    
    let data = {}

    // Model Parameters
    data[con.REDUCER_MODEL_PARAMETERS] = store.getState()[con.STORE][con.REDUCER_MODEL_PARAMETERS]

    // Forward Coverages
    data[con.REDUCER_FWD_COVERAGES] = Object.values(store.getState()[con.STORE][con.REDUCER_FWD_COVERAGES])

    // Spot Coverages
    data[con.REDUCER_SPOT_COVERAGES] = Object.values(store.getState()[con.STORE][con.REDUCER_SPOT_COVERAGES])

    // Start date
    data[con.START_DATE] = startDate

    //endDate
    data[con.END_DATE] = endDate

    // Include purchases
    data[con.INCLUDE_PURCHASES] = includePurchases

    data[con.OPERATION] = operation

    // Surrogate user
    let url = con.rest_base_url + con.reports_api +  con.purchase_reporte_url
    if(surrogateActive())
    {
        data[con.SURROGATE_USER] = getSurrogateUser()[con.ID]
        url = con.rest_base_url + con.reports_api + con.STAFF_API + con.purchase_reporte_url
    }
        
    let config = getRequestConfig()

    axios.post(url, data, {responseType: 'blob' , ...config})
            .then(resp => {
                
                setApplicationParameter(con.PURCHASE_REPORT, {[con.GENERATING] : false}) 

                downloadFileFromResponse(resp, constructFileName(con.PURCHASE_REPORT_NAME, con.PDF_FILE_TYPE)) 
                createNotification("Reporte Descargado")
                
            })
            .catch((err) => {
                setApplicationParameter(con.PURCHASE_REPORT, {[con.GENERATING] : false,
                                                        [con.REPORT_FILE_NAME] : null}) 
                handleRequestError(err)
                
            })
    
}



export const makePositionReport = async () =>
{   
    setApplicationParameter(con.POSITION_REPORT, {[con.GENERATING] : true,
                            [con.REPORT_FILE_NAME] : null}) 
    
    let data = {}

    let scenarios = store.getState()[con.STORE][con.REDUCER_SCENARIOS]

    // Scenarios
    data[con.REDUCER_SCENARIOS]= deleteAttributeFromObject(scenarios, con.GENERATING_FUNCTION)

    // Model Parameters
    data[con.REDUCER_MODEL_PARAMETERS] = store.getState()[con.STORE][con.REDUCER_MODEL_PARAMETERS]
    
    // Adds missing values
    data[con.REDUCER_MODEL_PARAMETERS][con.SELECTED_SCENARIO] = getApplicationParameter(con.SELECTED_SCENARIO)
    data[con.REDUCER_MODEL_PARAMETERS][con.SPOT] = getApplicationParameter(con.SPOT)

    // Model Application Parameters
    let modelApplicationParameters = getModelApplicationParameters()

    

    // Exposures
    data[con.REDUCER_EXPOSURES] = Object.values(store.getState()[con.STORE][con.REDUCER_EXPOSURES])

    // Forward Coverages
    data[con.REDUCER_FWD_COVERAGES] = Object.values(store.getState()[con.STORE][con.REDUCER_FWD_COVERAGES])

    // Forward Coverages
    data[con.REDUCER_OPTION_COVERAGES] = Object.values(store.getState()[con.STORE][con.REDUCER_OPTION_COVERAGES])

    // Spot Coverages
    data[con.REDUCER_SPOT_COVERAGES] = Object.values(store.getState()[con.STORE][con.REDUCER_SPOT_COVERAGES])
    
    // Accounts
    data[con.REDUCER_ACCOUNTS] = Object.values(store.getState()[con.STORE][con.REDUCER_ACCOUNTS])


    let transSummary = await processTransactionsWithSummary(getApplicationParameter(con.CURRENT_DATE),
                                                                getApplicationParameter(con.SPOT),
                                                                scenarios[data[con.REDUCER_MODEL_PARAMETERS][con.SELECTED_SCENARIO]].gen_fun,
                                                                data[con.REDUCER_MODEL_PARAMETERS], 
                                                                data[con.REDUCER_EXPOSURES],
                                                                data[con.REDUCER_FWD_COVERAGES],
                                                                data[con.REDUCER_SPOT_COVERAGES],
                                                                data[con.REDUCER_ACCOUNTS],
                                                                modelApplicationParameters)

    
    data[con.TRANSACTION_SUMMARY] = transSummary[0]

    // Surrogate user
    let url = con.rest_base_url + con.reports_api +  con.position_reporte_url
    if(surrogateActive())
    {
        data[con.SURROGATE_USER] = getSurrogateUser()[con.ID]
        url = con.rest_base_url + con.reports_api + con.STAFF_API + con.position_reporte_url
    }
        
    let config = getRequestConfig()

    axios.post(url, data, {responseType: 'blob' , ...config})
            .then(resp => {
                
                setApplicationParameter(con.POSITION_REPORT, {[con.GENERATING] : false}) 
                
                downloadFileFromResponse(resp, constructFileName(con.POSITION_REPORT_NAME, con.PDF_FILE_TYPE))

                createNotification("Reporte Descargado")

                
            })
            .catch((err) => {
                setApplicationParameter(con.POSITION_REPORT, {[con.GENERATING] : false,
                                                        [con.REPORT_FILE_NAME] : null}) 
                handleRequestError(err)
                
            })
    
}



export const makeForwardAssessmentReport = (assessmentSummary, assessedFwdArray) =>
{   
    setApplicationParameter(con.FORWARD_ASSESSMENT_REPORT, {[con.GENERATING] : true,
                                                            [con.REPORT_FILE_NAME] : null}) 
    
    let data = {}

    // Forward Coverages (with assessment)
    data[con.REDUCER_FWD_COVERAGES] = assessedFwdArray

    // Forward Coverages Summary
    data[con.SUMMARY] = assessmentSummary

    const applicationParameters = store.getState()[con.STORE][con.REDUCER_APPLICATION_PARAMETERS]

    // Adds Parameters
    data[con.PARAMETERS] = {
        [con.FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES] : applicationParameters[con.FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES],
        [con.FORWARD_ASSESSMENT_REFERENCE_DATE] : applicationParameters[con.FORWARD_ASSESSMENT_REFERENCE_DATE],
        [con.FORWARD_ASSESSMENT_START_DATE] : applicationParameters[con.FORWARD_ASSESSMENT_START_DATE],
        [con.USER] : surrogateActive() ? getSurrogateUser()[con.ID] : getUser()[con.ID]
    }

    //console.log(JSON.stringify(data, null, 3))

    let url = con.rest_finance_url + con.reports_finance +  con.forward_assessment_report_url
    const config = getFinanceRequestConfig()
    config.responseType = 'blob';
    axios.post(url, data, config)
            .then(resp => {
                
                setApplicationParameter(con.FORWARD_ASSESSMENT_REPORT, {[con.GENERATING] : false}) 

                downloadFileFromResponse(resp, constructFileName(con.FORWARD_ASSESSMENT_REPORT_NAME, con.PDF_FILE_TYPE)) 
                createNotification("Reporte Descargado")
                
            })
            .catch((err) => {
                setApplicationParameter(con.FORWARD_ASSESSMENT_REPORT, {[con.GENERATING] : false,
                                                        [con.REPORT_FILE_NAME] : null}) 
                handleRequestError(err)
                
            })
    
}

export const makeOptionAssessmentReport = (assessmentSummary, assessedOptionArray) =>
    {   
        setApplicationParameter(con.OPTION_ASSESSMENT_REPORT, {[con.GENERATING] : true,
                                                                [con.REPORT_FILE_NAME] : null}) 
        
        let data = {}
    
        // Option Coverages (with assessment)
        data[con.REDUCER_OPTION_COVERAGES] = assessedOptionArray
    
        // Option Coverages Summary
        data[con.SUMMARY] = assessmentSummary
    
        const applicationParameters = store.getState()[con.STORE][con.REDUCER_APPLICATION_PARAMETERS]
        // Adds Parameters
        data[con.PARAMETERS] = {
            [con.OPTION_ASSESSMENT_SHOW_EXPIRED_COVERAGES] : applicationParameters[con.OPTION_ASSESSMENT_SHOW_EXPIRED_COVERAGES],
            [con.OPTION_ASSESSMENT_REFERENCE_DATE] : applicationParameters[con.OPTION_ASSESSMENT_REFERENCE_DATE],
            [con.OPTION_ASSESSMENT_START_DATE] : applicationParameters[con.OPTION_ASSESSMENT_START_DATE]
        }
    
    
        // Surrogate user
        let url = con.rest_base_url + con.reports_api +  con.option_assessment_report_url
        if(surrogateActive())
        {
            data[con.SURROGATE_USER] = getSurrogateUser()[con.ID]
            url = con.rest_base_url + con.reports_api + con.STAFF_API + con.option_assessment_report_url
        }
        
        let config = getRequestConfig()
    
        axios.post(url, data, {responseType: 'blob' , ...config})
                .then(resp => {
                    
                    setApplicationParameter(con.FORWARD_ASSESSMENT_REPORT, {[con.GENERATING] : false}) 
    
                    downloadFileFromResponse(resp, constructFileName(con.FORWARD_ASSESSMENT_REPORT_NAME, con.PDF_FILE_TYPE)) 
                    createNotification("Reporte Descargado")
                    
                })
                .catch((err) => {
                    setApplicationParameter(con.FORWARD_ASSESSMENT_REPORT, {[con.GENERATING] : false,
                                                            [con.REPORT_FILE_NAME] : null}) 
                    handleRequestError(err)
                    
                })
        
    }


// Method that gets the inputTemplate
export const downloadInputsTemplate = (clientType) =>
{

    const URL = con.rest_base_url + con.templates_api
    let fileName = con.INPUT_TEMPLATE_NAME_IMPORTER
    if(clientType === con.EXPORTER)
        fileName = con.INPUT_TEMPLATE_NAME_EXPORTER
     
    downloadFile(URL, fileName, {[con.TEMPLATE_NAME] : fileName })
}

export const constructFileName = (fileName, fileType) =>
{   
    let response = `${formatDate(getToday())} ${fileName} ${getVisualizedUser()[con.USERNAME]}${fileType}`
    if(fileType === con.XLSX_FILE_TYPE && response.length > con.MAX_EXCEL_SHEET_NAME)
    {
        // Adjusts
        response = `${formatDate(getToday())} ${fileName}`
        response = response.substring(0, Math.min(response.length, con.MAX_EXCEL_SHEET_NAME - fileType.length))

        // Adds encoding
        response = `${response}${fileType}`
    }
        


    return response 
}

// Downloads generic file from server 
const downloadFile = (URL, fileName, data) =>
{
    axios.post(URL, data, {responseType: 'blob' })
        .then((resp)=>{
            downloadFileFromResponse(resp, fileName)
        })
        .catch(err => 
            handleRequestError(err)
        )
}

export const makeCommitteeReport = ({startDate, endDate, bandsInfo}) =>
{   
    setApplicationParameter(con.COMMITTEE_REPORT, {[con.GENERATING] : true,
                                                    [con.REPORT_FILE_NAME] : null}) 

    let data = {}

    // Average Bands
    data[con.AVERAGE_FOR_PERCENTAGE] = bandsInfo[con.AVERAGE_FOR_PERCENTAGE]    
    
    // Position data
    data[con.POSITION_IN_BANDS_DATA] = bandsInfo[con.POSITION_IN_BANDS_DATA]

    // Position Summary
    data[con.POSITION_IN_BANDS] = bandsInfo[con.POSITION_IN_BANDS]

    // Model Parameters
    data[con.REDUCER_MODEL_PARAMETERS] = store.getState()[con.STORE][con.REDUCER_MODEL_PARAMETERS]

    // Forward Coverages
    data[con.REDUCER_FWD_COVERAGES] = Object.values(store.getState()[con.STORE][con.REDUCER_FWD_COVERAGES])

    // Spot Coverages
    data[con.REDUCER_SPOT_COVERAGES] = Object.values(store.getState()[con.STORE][con.REDUCER_SPOT_COVERAGES])

    // Start date
    data[con.START_DATE] = startDate

    //endDate
    data[con.END_DATE] = endDate

    // Surrogate user
    let url
    if(surrogateActive())
    {
        data[con.SURROGATE_USER] = getSurrogateUser()[con.ID]
        url = con.rest_base_url + con.reports_api + con.STAFF_API + con.committee_report_url
    }
        
    let config = getRequestConfig()

    axios.post(url, data, {responseType: 'blob' , ...config})
            .then(resp => {
                
                setApplicationParameter(con.COMMITTEE_REPORT, {[con.GENERATING] : false}) 

                downloadFileFromResponse(resp, constructFileName(con.COMMITTEE_REPORT, con.PPTX_FILE_TYPE)) 
                createNotification("Reporte Descargado")
                
            })
            .catch((err) => {
                setApplicationParameter(con.COMMITTEE_REPORT, {[con.GENERATING] : false,
                                                        [con.REPORT_FILE_NAME] : null}) 
                handleRequestError(err)
                
            })
    
}








