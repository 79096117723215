// Global view page
// TODO - Documentar
import React from 'react'
import "./Pages.css"


import * as con from "../GlobalConstants"


import BandComparisonGlobalViewComponent from '../components/globalViewComponents/BandComparisonGlobalViewComponent';
import MarketViewGlobalViewComponent from '../components/globalViewComponents/MarketViewGlobalViewComponent';
import CurrentPositionGlobalViewComponent from '../components/globalViewComponents/CurrentPositionGlobalViewComponent';
import ScenariosGlobalViewComponent from '../components/globalViewComponents/ScenariosGlobalViewComponent';


function GlobalView() {


    const componentHeight = con.USABLE_SCREEN_SIZE/2

    

    return (      
            <div>
                <div className="gridBox" style={{minHeight : `${componentHeight}vh`}}>
                    <MarketViewGlobalViewComponent/>
                    <CurrentPositionGlobalViewComponent componentHeight={componentHeight}/>        
                </div>
                <div className="gridBox" style={{height : `${componentHeight-2}vh`, marginTop : "0vh"}}>
                    <ScenariosGlobalViewComponent/>
                    <BandComparisonGlobalViewComponent />
                </div>        
            </div>
    )
}




export default GlobalView
