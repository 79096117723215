import React from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import { useSelector } from 'react-redux';
import MarketSummaryRow from './MarketSummaryRow';
import { fontCalculator } from '../../templates/tables/StaticTable'; 

function MarketSummaryTable({ fixedSize, filteredData }) {
    const marketSummaries = useSelector((state) => state['store']['market_values']['market_summaries']);

    if (!marketSummaries) {
        return <div>No hay datos disponibles</div>;
    }

    const summaryData = Object.keys(marketSummaries).map((period, index) => ({
        rowId: index,
        periodo: period,
        volatilidad: marketSummaries[period]['volatility'],
        tasaPromedio: marketSummaries[period]['average'],
        volPromedio: marketSummaries[period]['vol_promedio'],
        max: marketSummaries[period]['max'],
        min: marketSummaries[period]['min'],
    }));

    const calculateFiltroSummary = (data) => {
        if (data.length === 0) return { volatilidad: 0, tasaPromedio: 0 };
    
        const closePrices = data.map(item => item.cierre);
    
        const tasaPromedio = closePrices.reduce((acc, curr) => acc + curr, 0) / closePrices.length;
    
        const returns = closePrices.slice(1).map((price, i) => price / closePrices[i] - 1);
        const meanReturn = returns.reduce((acc, curr) => acc + curr, 0) / returns.length;
        const variance = returns.reduce((acc, curr) => acc + Math.pow(curr - meanReturn, 2), 0) / (returns.length - 1);
        const volatility = Math.sqrt(variance) * Math.sqrt(252); 
    
        return { volatilidad: volatility, tasaPromedio };
    };

    const filtroSummary = calculateFiltroSummary(filteredData);

    summaryData.push({
        rowId: summaryData.length,
        periodo: 'Filtro',
        volatilidad: filtroSummary.volatilidad,
        tasaPromedio: filtroSummary.tasaPromedio,
        volPromedio: null,
        max: null,
        min: null,
    });

    return (
        <div className="contenedor-tabla" style={fixedSize == null ? {} : { fontSize: fontCalculator(fixedSize, summaryData.length) }}>
            <div className="flex-tabla">
                <div className="flex-fila header-row">
                    <div className="flex-celda" style={{ padding: '5px 11px', textAlign: 'center' }}>Periodo</div>
                    <div className="flex-celda" style={{ padding: '5px 11px', textAlign: 'center' }}>Volatilidad</div>
                    <div className="flex-celda" style={{ padding: '5px 11px', textAlign: 'center' }}>Tasa Promedio</div>
                    <div className="flex-celda" style={{ padding: '5px 11px', textAlign: 'center' }}>Vol Promedio</div>
                    <div className="flex-celda" style={{ padding: '5px 11px', textAlign: 'center' }}>Máx</div>
                    <div className="flex-celda" style={{ padding: '5px 11px', textAlign: 'center' }}>Mín</div>
                </div>
            </div>

            <div className="flex-tabla">
                {summaryData.map((data, index) => (
                    <MarketSummaryRow key={index} rowId={index} data={data} />
                ))}
            </div>
        </div>
    );
}

MarketSummaryTable.defaultProps = {
    fixedSize: null,
    filteredData: [],
};

export default MarketSummaryTable;
