// TODO - Documentar
import React, { useEffect } from 'react'
import {TransactionsSummary} from '../components/transactionsSummary/TransactionsSummary'
import { CurrentPositionParametersMobile } from '../components/parameters/CurrentPositionParameters'
import { setApplicationParameter } from '../store/actions/applicationParameters'
import * as con from "../GlobalConstants"
import { mobileSelectedTransactionCols } from '../utils/transactionFunctions'


function MobileCurrentPositionPage() {

    useEffect(() => {
      
        setApplicationParameter(con.VISUALIZATION_PERIODICITY, con.PERIODICITY_MONTH )
        setApplicationParameter(con.ONLY_NON_ZERO_ROWS, true)

    }, [])
    


    return (
        <div style={{minHeight: con.MOBILE_USABLE_SCREEN_SIZE + "vh"}}> 
            <div style={{ marginTop : "20px"}}>
                <CurrentPositionParametersMobile />
            </div>
            <div style={{ paddingLeft : '15px', paddingRight : '15px'}}>
                <TransactionsSummary selectedColumns={mobileSelectedTransactionCols}/>
            </div>
        </div>
    )
}

export default MobileCurrentPositionPage
